<template>
  <div>
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
      جدید +
    </button>

    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div v-else>
      <div class="mt-3" v-for="ip in ips" :key="ip">
        <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">{{ ip.ip.address }}
          <span class="badge rounded-pill bg-secondary me-2" v-if="ip.plan == 'free'">رایگان</span>
          <span class="badge rounded-pill bg-info me-2" v-if="ip.plan == 'standard'">اشتراکی</span>
          <span class="badge rounded-pill bg-primary me-2" v-if="ip.plan == 'pro'">اختصاصی</span>
          <router-link class="btn btn-primary me-auto" :to="'/dashboard/ip/manage/' + ip._id">مدیریت</router-link>
        </div>
      </div>
    </div>


    <div class="modal fade" id="exampleModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">آی پی جدید</h5>
          </div>
          <div class="modal-body">
            <input type="radio" class="btn-check" name="options-outlined" id="opt1" value="free" v-model="newIP">
            <label class="btn btn-outline-success opt" for="opt1">رایگان</label>
            <br>
            <input type="radio" class="btn-check" name="options-outlined" id="opt2" value="standard" v-model="newIP">
            <label class="btn btn-outline-success opt" for="opt2">اشتراکی</label>
            <br>
            <input type="radio" class="btn-check" name="options-outlined" id="opt3" value="pro" v-model="newIP">
            <label class="btn btn-outline-success opt" for="opt3">اختصاصی</label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="send()" :disabled="loading">
              خرید
              <span class="spinner-border spinner-border-sm" v-show="loading"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import axios from 'axios';
import { inject } from '@vue/runtime-core';
import { onMounted } from '@vue/runtime-core';
export default {

  setup() {
    const newIP = ref('');
    const ips = ref([]);
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const modal = ref();
    function send() {
      loading.value = true;
      axios.post('/dashboard/new-ip', {
        plan: newIP.value,
      })
        .then(function (response) {
          loading.value = false;
          toast.success("خرید IP با موفقیت انجام شد");
          modal.value.click();
          getData()
          console.log(response.data);
        })
        .catch(function (error) {
          if (error.response.data.status == "error-value") {
            toast.error("موجودی به اتمام رسیده است");
          } else {
            toast.error("خرید IP با خطا مواجه شد");
          }
          loading.value = false;
          modal.value.click();
        })
    }
    function getData() {
      axios.post('/dashboard/get-services', {})
        .then(function (response) {
          ips.value = response.data;
          get_data_loading.value = false;
        })
        .catch(function (error) {
          toast.error("خطا در دریافت اطلاعات");
          get_data_loading.value = false;
        })
    }

    onMounted(() => {
      getData()
    })

    return { ips, newIP, send, loading, modal, get_data_loading }
  }

}
</script>

<style scoped>
.opt {
  width: 100%;
  margin-bottom: 10px;
}
</style>