<template>
  <div>
    <div class="shadow p-3 mb-3 bg-body rounded">
      <div class="row g-3">
        <div class="col-md-6">
          <label for="mobile" class="form-label">شماره موبایل</label>
          <input type="text" class="form-control" id="mobile" disabled :value="profile.mobile" />
        </div>
        <div class="col-md-6">
          <label for="name" class="form-label">نام و نام خانوادگی</label>
          <input type="text" class="form-control" id="name" disabled :value="profile.name">
        </div>
      </div>
    </div>
    مشخصات تکمیلی
    <div class="shadow p-3 mb-3 mt-2 bg-body rounded">
      <div class="row g-3">
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">ایمیل</label>
          <input type="text" :class="'form-control ' + validation.email" id="inputEmail4"
            placeholder="ایمیل خودرا وارد کنید" v-model="data.email" :disabled="profile.level" @change="check">
        </div>
        <div class="col-md-6">
          <label for="nationalID" class="form-label">کد ملی</label>
          <input type="text" :class="'form-control ' + validation.nationalid" id="nationalID"
            placeholder="کد ملی خودرا وارد کنید" v-model="data.nationalid" :disabled="profile.level" @change="check">
        </div>
        <div class="col-8">
          <label for="address" class="form-label">آدرس</label>
          <input type="text" :class="'form-control ' + validation.address" id="address"
            placeholder="آدرس خود را وارد کنید" v-model="data.address" :disabled="profile.level" @change="check">
        </div>
        <div class="col-4">
          <label for="city" class="form-label">شهر</label>
          <input type="text" :class="'form-control ' + validation.city" id="city" placeholder="شهر خود را وارد کنید"
            v-model="data.city" :disabled="profile.level" @change="check">
        </div>
        <div class="col-md-4">
          <label for="postalcode" class="form-label">کد پستی</label>
          <input type="text" :class="'form-control ' + validation.postalcode" id="postalcode"
            placeholder="کد پستی خود را وارد کنید" v-model="data.postalcode" :disabled="profile.level" @change="check">
        </div>
        <div class="col-12" v-show="!profile.level">
          <button type="submit" class="btn btn-primary" @click="send">ثبت مشخصات</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const modal = ref();
    const profile = ref({});
    const data = ref({});
    const validation = ref({
      email: '',
      nationalid: '',
      address: '',
      city: '',
      postalcode: '',
    })
    function check() {

      if (data.value.email == "" || data.value.email == null) validation.value.email = "is-invalid";
      else validation.value.email = "is-valid"

      if (data.value.nationalid == "" || data.value.nationalid == null) validation.value.nationalid = "is-invalid";
      else validation.value.nationalid = "is-valid"

      if (data.value.address == "" || data.value.address == null) validation.value.address = "is-invalid";
      else validation.value.address = "is-valid"

      if (data.value.city == "" || data.value.city == null) validation.value.city = "is-invalid";
      else validation.value.city = "is-valid"

      if (data.value.postalcode == "" || data.value.postalcode == null) validation.value.postalcode = "is-invalid";
      else validation.value.postalcode = "is-valid"
    }

    function send() {
      if (validation.value.email == "is-valid" &&
        validation.value.nationalid == "is-valid" &&
        validation.value.address == "is-valid" &&
        validation.value.city == "is-valid" &&
        validation.value.postalcode == "is-valid") {

        loading.value = true;
        axios.post('/dashboard/profile-complete', data.value)
          .then(function (response) {
            getData()
            loading.value = false;
            toast.success("ثبت مشخصات  با موفقیت انجام شد");
          })
          .catch(function (error) {
            toast.error("ثبت مشخصات با مشکل مواجه شد");
            loading.value = false;
            console.log(error);
          })
      }
    }
    function getData() {
      axios.post('/dashboard/get-profile', {})
        .then(function (response) {
          if(response.data.level){
            data.value.email = response.data.email;
            data.value.nationalid = response.data.nationalid;
            data.value.address = response.data.address;
            data.value.city = response.data.city;
            data.value.postalcode = response.data.postalcode;
          }
          profile.value = response.data;
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }
    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, modal, profile, send, data, check, validation }
  }

}
</script>

<style></style>