<template>
  <div>

    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
      جدید +
    </button>


    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div class="row" v-else>
      <div class="mt-3 col-md-4" v-for="plan in plans" :key="plan">
        <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">
          {{ plan.size }} GB
          <span class="badge rounded-pill bg-success me-1" v-if="plan.active == 1">فعال</span>
          <span class="badge rounded-pill bg-danger me-1" v-if="plan.active == 0">غیر فعال</span>
          <button class="btn btn-primary me-auto" data-bs-toggle="modal" data-bs-target="#edit"
            @click="getPlan(plan._id)">ویرایش</button>
        </div>
      </div>
    </div>


    <div class="modal fade" id="exampleModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">پلن جدید</h5>
          </div>
          <div class="modal-body">

            <div dir="ltr">
              سایز
              <div class="col-md-12 m-0 mb-2">
                <input type="text" class="form-control" placeholder="سایز GB" v-model="data.size">
              </div>


              قیمت ساعتی
              <div class="col-md-12 m-0">
                <input type="text" class="form-control" placeholder="قیمت" v-model="data.price">
              </div>

              <div class="form-check form-check mt-2">
                <input class="form-check-input" type="checkbox" checked v-model="data.active">
                <label class="form-check-label" for="inlineCheckbox1">فعال</label>
              </div>

            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="send()" :disabled="loading">
              افزودن
              <span class="spinner-border spinner-border-sm" v-show="loading"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Edit Modal-->
    <div class="modal fade" id="edit" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">ویرایش</h5>
          </div>
          <div class="modal-body">
            <div v-if="edit_data_loading">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
            <div dir="ltr" v-else>
              سایز
              <div class="col-md-12 m-0 mb-2">
                <input type="text" class="form-control" placeholder="سایز GB" v-model="edit_data.size">
              </div>
              قیمت ساعتی
              <div class="col-md-12 m-0">
                <input type="text" class="form-control" placeholder="قیمت" v-model="edit_data.price">
              </div>
              <div class="form-check form-check mt-2">
                <input class="form-check-input" type="checkbox" checked v-model="edit_data.active">
                <label class="form-check-label" for="inlineCheckbox1">فعال</label>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="edit()" :disabled="loading">
              ویرایش
              <span class="spinner-border spinner-border-sm" v-show="loading"></span>
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
  
<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const edit_data_loading = ref(false);
    const toast = inject("toast");
    const modal = ref();
    const plans = ref([]);
    const edit_data = ref({})
    const data = ref({
      active: true
    });

    function send() {
      loading.value = true;
      axios.post('/admin/new-storage-plan', data.value)
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("افزودن پلن با موفقیت انجام شد");
          modal.value.click();
          console.log(response.data);
        })
        .catch(function (error) {
          toast.error("افزودن پلن با خطا مواجه شد");
          loading.value = false;
          modal.value.click();
          console.log(error);
        })
    }

    function edit() {
      loading.value = true;
      axios.post('/admin/edit-storage-plan', edit_data.value)
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("ویرایش پلن با موفقیت انجام شد");
          modal.value.click();
        })
        .catch(function (error) {
          toast.error("ویرایش پلن با خطا مواجه شد");
          loading.value = false;
          modal.value.click();
          console.log(error);
        })
    }

    function getData() {
      axios.post('/admin/get-storage-plans', {})
        .then(function (response) {
          plans.value = response.data
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }

    function getPlan(id) {
      edit_data_loading.value = true;
      axios.post('/admin/get-storage-plan', { id: id })
        .then(function (response) {
          edit_data.value = response.data
          edit_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          edit_data_loading.value = false;
        })
    }
    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, modal, plans, data, send, edit, edit_data_loading, edit_data, getPlan }
  }

}
</script>
  
<style></style>