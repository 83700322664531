<template>
    <div>
        <nav class="navbar navbar-light bg-light">
            <div class="container-fluid">
                <a class="navbar-brand" href="https://jupyto.com">
                    <img src="@/assets/images/logo.png" class="logo">
                </a>
            </div>
        </nav>
        <div class="container">

            <div class="shadow p-3 bg-body rounded register">
                <h3 class="text-center mb-3">ثبت نام</h3>
                <div class="mb-3" v-if="register_step == 0">
                    <label for="phone" class="form-label">شماره موبایل</label>
                    <input type="text" :class="'form-control ' + valid_mobile" id="phone"
                        placeholder="شماره موبایل خود را وارد کنید" v-model="data.mobile" @change="check">
                </div>
                <div class="mb-3" v-else-if="register_step == 1">
                    <label for="code" class="form-label">کد یکبار مصرف</label>
                    <input type="number" class="form-control" id="code" placeholder="کد یکبار مصرف ارسال شده را وارد کنید"
                        v-model="data.code">
                </div>
                <div v-else-if="register_step == 2">
                    <div class="mb-3">
                        <label for="name" class="form-label">نام و نام خانوادگی</label>
                        <input type="text" :class="'form-control ' + valid_name" id="name"
                            placeholder="نام و نام خانوادگی خود را وارد کنید" v-model="data.name" @change="check">
                    </div>
                    <div class="mb-3">
                        <label for="name" class="form-label">رمز عبور</label>
                        <input type="password" :class="'form-control ' + valid_password" id="name"
                            placeholder="رمز عبور خود را وارد کنید" v-model="data.password" @change="check">
                        <div id="name-invalid" class="invalid-feedback">
                            باید شامل اعداد، حروف بزرگ، کاراکتر خاص و حداقل به طول 8 باشد
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="name" class="form-label">تکرار رمز عبور</label>
                        <input type="password" :class="'form-control ' + valid_repassword" id="name"
                            placeholder="تکرار رمز عبور خود را وارد کنید" v-model="data.repassword" @change="check">
                    </div>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-primary col-12" @click="send" :disabled="loading">
                        ثبت نام
                        <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                    </button>
                </div>
                <p class="text-center mt-2 m-0 small-text">حساب کاربری دارید؟ <router-link
                        to="/dashboard/login">ورود</router-link></p>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { inject, ref } from 'vue';
import { passwordStrength } from 'check-password-strength'
import router from '@/router';
export default {
    setup() {
        const loading = ref(false);
        const toast = inject("toast");
        const data = ref({
            mobile: '',
            name: '',
        });
        const register_step = ref(0);
        const valid_mobile = ref('');
        const valid_name = ref('');
        const valid_password = ref('');
        const valid_repassword = ref('');
        function check() {
            if (register_step.value == 0) {
                const number = data.value.mobile
                if (number.length == 11) {
                    for (var i = 0; i < 11; i++) {
                        if (!(number[i] >= 0 && number[i] <= 9)) {
                            valid_mobile.value = "is-invalid";
                            return;
                        }
                    }
                    valid_mobile.value = "is-valid";
                } else {
                    valid_mobile.value = "is-invalid";
                }
            } else if (register_step.value == 2) {
                if (data.value.name.length > 3) {
                    valid_name.value = "is-valid";
                } else {
                    valid_name.value = "is-invalid";
                }
                if (data.value.password == data.value.repassword) {
                    valid_repassword.value = "is-valid";
                } else {
                    valid_repassword.value = "is-invalid";
                }
                if (passwordStrength(data.value.password).value == 'Weak' ||
                    passwordStrength(data.value.password).value == 'Too weak') {
                    valid_password.value = "is-invalid";
                } else {
                    valid_password.value = "is-valid";
                }
            }



        }
        function send() {

            if (register_step.value == 0) {
                if (valid_mobile.value == "is-valid") {
                    loading.value = true;
                    axios.post('/user/register-send-otp', data.value)
                        .then(function (response) {
                            loading.value = false;
                            toast.success("کد یکبار مصرف ارسال شد");
                            register_step.value = 1;
                        })
                        .catch(function (error) {
                            toast.error("ارسال کد یکبار مصرف با خطا مواجه شد");
                            loading.value = false;
                            console.log(error);
                        })
                }
            } else if (register_step.value == 1) {

                if (data.value.code != null) {
                    loading.value = true;
                    axios.post('/user/register-verify-otp', data.value)
                        .then(function (response) {
                            loading.value = false;
                            toast.success("کد یکبار مصرف تایید شد");
                            register_step.value = 2;
                        })
                        .catch(function (error) {
                            toast.error("کد ارسالی اشتباه است");
                            loading.value = false;
                            console.log(error);
                        })
                }
            } else if (register_step.value == 2) {
                if (valid_name.value == "is-valid" &&
                    valid_password.value == "is-valid" &&
                    valid_repassword.value == "is-valid") {

                    loading.value = true;
                    axios.post('/user/register-final', data.value)
                        .then(function (response) {
                            loading.value = false;
                            toast.success("ثبت نام با موفقیت انجام شد");
                            router.push('/dashboard/login')
                        })
                        .catch(function (error) {
                            toast.error("ثبت نام ناموفق بود");
                            loading.value = false;
                            register_step.value = 0;
                            console.log(error);
                        })

                }
            }

        }

        return { loading, send, data, register_step, check, valid_mobile, valid_name, valid_password, valid_repassword }
    }

}
</script>

<style scoped>
.register {
    width: 500px;
    margin: 0;
    position: absolute;
    margin-top: 50px;
    left: 50%;
    transform: translate(-50%, 50%);

}

.small-text {
    font-size: 15px;
}

.container {
    height: 100%;
    position: relative;
}

.logo {
    display: block;
    height: 30px;
}

@media screen and (max-width: 600px) {
    .register {
        width: 90%;
    }
}
</style>