<template>
  <div>

    <p>سرور: {{ route.params.id }}</p>

    <div class="shadow p-3 mb-3 bg-body rounded">
      //BUG//
      <div class="">
        <div dir="ltr">

          <div class="mb-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="jupyter"
                v-model="data.type">
              <label class="form-check-label" for="inlineRadio1">jupyter Server</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="server"
                v-model="data.type">
              <label class="form-check-label" for="inlineRadio2">GPU Server</label>
            </div>
          </div>

          <div class="row m-0">
            <div class="col-md-12 m-0 mb-2">
              <label class="form-label">IP</label>
              <input type="text" class="form-control" placeholder="ip" v-model="data.ip">
            </div>
            <div class="col-md-6 m-0 mb-2">
              <label class="form-label">Username</label>
              <input type="text" class="form-control" placeholder="username" v-model="data.username">
            </div>
            <div class="col-md-6 m-0 mb-2">
              <label class="form-label">Password</label>
              <input type="text" class="form-control" placeholder="password" v-model="data.password">
            </div>
          </div>

          <div class="input-group row m-0 mb-2">
            <div class="col-md-4">
              <label class="form-label">CPU</label>
              <select class="form-select" v-model="data.cpu">
                <option v-for="hardware in hardwares.cpu" :key="hardware" :value="hardware._id">{{
                  hardware.name }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label class="form-label">میزان</label>
              <input type="text" class="form-control" placeholder="میزان" v-model="data.cpu_value">
            </div>
            <div class="col-md-4">
              <label class="form-label">باقی مانده</label>
              <input type="text" class="form-control" v-model="data.cpu_remaining" disabled>
            </div>
          </div>

          <div class="input-group row m-0 mb-2">
            <div class="col-md-4">
              <label class="form-label">GPU</label>
              <select class="form-select" v-model="data.gpu">
                <option v-for="hardware in hardwares.gpu" :key="hardware" :value="hardware._id">{{
                  hardware.name }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label class="form-label">میزان</label>
              <input type="text" class="form-control" placeholder="میزان" v-model="data.gpu_value">
            </div>
            <div class="col-md-2">
              <label class="form-label">باقی مانده</label>
              <input type="text" class="form-control" v-model="data.gpu_remaining" disabled>
            </div>
            <div class="col-md-2">
              <label class="form-label">Path</label>
              <input type="text" class="form-control" v-model="data.gpu_path" disabled>
            </div>
          </div>

          <div class="input-group row m-0 mb-2">
            <div class="col-md-4">
              <label class="form-label">Ram</label>
              <select class="form-select" v-model="data.ram">
                <option v-for="hardware in hardwares.ram" :key="hardware" :value="hardware._id">{{
                  hardware.name }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label class="form-label">میزان</label>
              <input type="text" class="form-control" placeholder="میزان" v-model="data.ram_value">
            </div>
            <div class="col-md-4">
              <label class="form-label">باقی مانده</label>
              <input type="text" class="form-control" v-model="data.ram_remaining" disabled>
            </div>
          </div>

          <div class="input-group row m-0 mb-2">
            <div class="col-md-4">
              <label class="form-label">Disk</label>
              <select class="form-select" v-model="data.disk">
                <option v-for="hardware in hardwares.disk" :key="hardware" :value="hardware._id">{{
                  hardware.name }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label class="form-label">میزان</label>
              <input type="text" class="form-control" placeholder="میزان" v-model="data.disk_value">
            </div>
            <div class="col-md-4">
              <label class="form-label">باقی مانده</label>
              <input type="text" class="form-control" v-model="data.disk_remaining" disabled>
            </div>
          </div>

          <div class="form-check form-check mt-2 ms-3 mt-3">
            <input class="form-check-input" type="checkbox" checked v-model="data.active">
            <label class="form-check-label" for="inlineCheckbox1">فعال</label>
          </div>

        </div>
        <div class="modal-footer me-3" dir="ltr">
          <button type="button" class="btn btn-primary" @click="send()" :disabled="loading">
            ویرایش
            <span class="spinner-border spinner-border-sm" v-show="loading"></span>
          </button>
        </div>
      </div>
    </div>

    <p>عملیات</p>
    <div class="shadow p-3 mb-3 bg-body rounded">
      <div class="row m-0">
        <button type="button" class="btn btn-primary col-md-2 mx-auto m-1">ری استارت</button>
      </div>
    </div>

    <p>سرویس ها</p>
    <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center" v-for="service in services" :key="service">
      <div>
        {{ service.plan.name }}
        <span class="badge rounded-pill bg-warning" v-if="service.status == 'pending'">انتظار</span>
        <span class="badge rounded-pill bg-warning" v-if="service.status == 'queue'">صف</span>
        <span class="badge rounded-pill bg-success" v-if="service.status == 'active'">فعال</span>
        <span class="badge rounded-pill bg-danger" v-if="service.status == 'error'">خطا</span>
        <span class="badge rounded-pill bg-primary" v-if="service.status == 'finished'">پایان</span>
        <span class="badge rounded-pill bg-secondary me-1">{{ service.user.name }}</span>
      </div>
      <div class="me-auto">
        <router-link class="btn btn-primary" :to="'/admin/services/' + service._id">مدیریت</router-link>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
export default {
  setup() {
    const route = useRoute()
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const data = ref({});
    const services = ref([]);
    const hardwares = ref({
      cpu: [],
      gpu: [],
      ram: [],
      disk: []
    });

    function send() {

      loading.value = true;
      axios.post('/admin/edit-server', data.value)
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("ثبت مشخصات  با موفقیت انجام شد");
        })
        .catch(function (error) {
          toast.error("ثبت مشخصات با مشکل مواجه شد");
          loading.value = false;
          console.log(error);
        })
    }

    function getData() {

      axios.post('/admin/get-hardwares', {})
        .then(function (response) {
          response.data.forEach(ha => {
            if (ha.type == 'cpu') {
              hardwares.value.cpu.push(ha)
            } else if (ha.type == 'gpu') {
              hardwares.value.gpu.push(ha)
            } else if (ha.type == 'ram') {
              hardwares.value.ram.push(ha)
            } else if (ha.type == 'disk') {
              hardwares.value.disk.push(ha)
            }
          });
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })

      axios.post('/admin/get-server-services', { id: route.params.id })
        .then(function (response) {
          services.value = response.data;
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })

      axios.post('/admin/get-server', { id: route.params.id })
        .then(function (response) {
          data.value = response.data;
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })

    }
    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, send, data, route, hardwares, services }
  }
}
</script>

<style></style>