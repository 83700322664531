<template>
  <div>

    <div class="mt-3 col-md-12">
      <div class="shadow p-3 mb-2 bg-body rounded d-flex align-items-center">
        <input type="search" class="form-control" id="exampleInputEmail1" placeholder="جست و جوی سرویس ..."
          v-model="search">
      </div>
    </div>

    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div class="row" v-else>
      <div class="mt-3" v-for="transaction in transactions" :key="transaction">
        <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">
          <box-icon name='up-arrow-alt' v-if="transaction.type == 'paas'" color='#E74C3C' size="md"></box-icon>
          <box-icon name='down-arrow-alt' v-else-if="transaction.type == 'input'" color='#27AE60' size="md"></box-icon>
          <div>{{ transaction.value.toLocaleString() }} تومان
            <span class="badge rounded-pill bg-warning" v-if="transaction.status == 'pending'">در انتظار</span>
            <span class="badge rounded-pill bg-success" v-else-if="transaction.status == 'complete'">انجام شده</span>
            <br>
            <small>{{ transaction.createdAt }}</small>
          </div>
          <div class="me-auto">
            <box-icon name='file' class="pointer" size="cssSize" title="جزئیات" color='#455A64' data-bs-toggle="modal"
              data-bs-target="#invoice" @click="getTransaction(transaction._id)"></box-icon>
          </div>
        </div>
      </div>
    </div>

    <nav aria-label="Page navigation" dir="ltr">
      <ul class="pagination justify-content-center">
        <li v-for="p in page" :key="p" class="page-item">
          <a v-if="p == active_page" class="page-link active" href="#" @click="getData(p)">{{ p }}</a>
          <a v-else class="page-link" href="#" @click="getData(p)">{{ p }}</a>
        </li>
      </ul>
    </nav>

    <!--Show Modal-->
    <div class="modal fade" id="invoice" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">مشاهده تراکنش</h5>
          </div>
          <div class="modal-body">
            <p>وضعیت:
              <span class="badge rounded-pill bg-warning" v-if="edit_data.status == 'pending'">در انتظار</span>
              <span class="badge rounded-pill bg-success" v-if="edit_data.status == 'complete'">انجام شده</span>
            </p>
            <p>تاریخ:
              <span>{{ edit_data.createdAt }}</span>
            </p>
            <p>نوع:
              <span v-if="edit_data.type == 'paas'">پرداخت ساعتی</span>
              <span v-if="edit_data.type == 'input'">شارژ کیف پول</span>
            </p>
            <p>مبلغ:
              <span>{{ edit_data.value.toLocaleString() }}</span>
              تومان
            </p>
            <p v-if="edit_data.service_type != null">
              سرویس:
              <span v-if="edit_data.service_type == 'storage'">دیسک ابری</span>
              <span v-if="edit_data.service_type == 'service'">سرویس پردازشی</span>
            </p>

            <p v-if="edit_data.type == 'input'">
              شماره پیگیری: {{ edit_data.tracking_id }}
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary">چاپ</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted, watch } from '@vue/runtime-core';
export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const modal = ref();
    const transactions = ref([]);
    const edit_data = ref({
      value: 0
    })
    const edit_data_loading = ref(false);
    const page = ref(1);
    const active_page = ref(0);
    const search = ref('')

    function getData(page_id, search = '') {
      active_page.value = page_id;
      axios.post('/admin/get-transactions', { page: page_id, search })
        .then(function (response) {
          transactions.value = response.data.transactions
          if (response.data.page != null) {
            page.value = parseInt(response.data.page / 20) + 1;
          }
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }

    function getTransaction(id) {
      edit_data_loading.value = true;
      axios.post('/admin/get-transaction', { id: id })
        .then(function (response) {
          edit_data.value = response.data
          edit_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          edit_data_loading.value = false;
        })
    }
    onMounted(() => {
      getData(1)
    })

    watch(search, (sr) => {
      getData(1, sr)
    })

    return { get_data_loading, loading, modal, transactions, edit_data, edit_data_loading, getTransaction, page, active_page, getData, search }
  }

}
</script>

<style></style>