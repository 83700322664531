<template>
  <div>

    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
      تیکت جدید +
    </button>


    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div class="row" v-else>
      <div class="mt-3 col-md-12" v-for="ticket in tickets" :key="ticket">
        <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">
          <div>
            {{ ticket.title }}
            <span class="badge rounded-pill bg-warning" v-if="ticket.status == 'open'">انتظار</span>
            <br>
            <small>بخش: {{ ticket.category }}</small>
          </div>

          <div class="me-auto">
            <router-link class="btn btn-primary" :to="'/dashboard/support/' + ticket._id">مشاهده</router-link>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="exampleModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">تیکت جدید</h5>
          </div>
          <div class="modal-body">

            <div class="mb-3">
              <label class="form-label">عنوان</label>
              <input type="text" class="form-control" placeholder="عنوان تیکت خود را وارد کنید" v-model="data.title">
            </div>
            <div class="mb-3">
              <label class="form-label">بخش مربوطه</label>
              <select class="form-select" v-model="data.category">
                <option value="support">پشتیبانی</option>
                <option value="financial">مالی</option>
                <option value="technical">فنی</option>
              </select>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="send()" :disabled="loading">
              ثبت
              <span class="spinner-border spinner-border-sm" v-show="loading"></span>
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
import router from '@/router';
export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const modal = ref();
    const tickets = ref([]);
    const data = ref({});

    function send() {
      loading.value = true;
      axios.post('/dashboard/new-ticket', data.value)
        .then(function (response) {
          getData()
          loading.value = false;
          router.push('/dashboard/support/' + response.data.id)
          toast.success("افزودن تیکت با موفقیت انجام شد");
          modal.value.click();
        })
        .catch(function (error) {
          toast.error("افزودن تیکت با خطا مواجه شد");
          loading.value = false;
          modal.value.click();
          console.log(error);
        })
    }


    function getData() {
      axios.post('/dashboard/get-tickets', {})
        .then(function (response) {
          tickets.value = response.data
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }

    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, modal, send, tickets, data }
  }

}
</script>

<style scoped>
.pointer {
  cursor: pointer;
  height: 30px;
  margin-right: 2px;
}
</style>