<template>
  <div>

    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div class="message-box" v-else>
      <div class="mt-3" v-for="message in messages" :key="message">
        <div v-if="message.side == 1">
          <div class="shadow p-3 mb-3 rounded col-md-5 bg-primary bg-gradient text-white">
            <p v-html="message.msg.replaceAll('\n', '<br>')"></p>
            <small>{{ message.date }}</small>
          </div>
        </div>
        <div v-else>
          <div class="shadow p-3 mt-5 mb-3 rounded col-md-5 me-auto">
            <p v-html="message.msg.replaceAll('\n', '<br>')"></p>
            <small>{{ message.date }}</small>
          </div>
        </div>

      </div>
    </div>

    <div class="mt-3">
      <div class="mb-3 d-flex align-items-center">
        <box-icon type='solid' name='send' @click="send()" class="ms-1 pointer"></box-icon>
        <textarea class="form-control" v-model="data.message" rows="2"></textarea>
        <label>
          <box-icon name='link-alt' class="me-1 pointer"></box-icon><input type="file" hidden>
        </label>
      </div>
    </div>


  </div>
</template>
  
<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const route = useRoute()
    const modal = ref();
    const messages = ref([]);
    const data = ref({});

    function send() {
      loading.value = true;
      axios.post('/admin/ticket-new-message', {
        id: route.params.id,
        message: data.value.message
      })
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("پیام با موفقیت ارسال شد");
          data.value.message = "";
        })
        .catch(function (error) {
          toast.error("خطا در ارسال پیام");
          loading.value = false;
          console.log(error);
        })
    }


    function getData() {
      axios.post('/admin/get-ticket', { id: route.params.id })
        .then(function (response) {
          messages.value = response.data.messages
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }

    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, modal, send, messages, data }
  }

}
</script>
  
<style scoped>
.pointer {
  cursor: pointer;
}


.message-box {
  overflow-y: auto;
  height: calc(100vh - 200px);
}
</style>