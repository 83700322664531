<template>
  <div>
    <nav class="navbar navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="https://jupyto.com">
          <img src="@/assets/images/logo.png" class="logo">
        </a>
      </div>
    </nav>
    <div class="container">
      <div class="shadow p-3 bg-body rounded register ">
        <h3 class="text-center mb-3">ورود به پنل</h3>
        <div class="mb-3">
          <label for="phone" class="form-label">شماره موبایل</label>
          <input type="text" :class="'form-control ' + valid_mobile" id="phone"
            placeholder="شماره موبایل خود را وارد کنید" v-model="data.mobile" @change="check">
        </div>



        <div class="mb-3">
          <label for="name" class="form-label">رمز عبور</label>
          <input type="password" :class="'form-control ' + valid_password" id="name"
            placeholder="رمز عبور خود را وارد کنید" v-model="data.password" @change="check">
        </div>

        <div class="col-12">
          <button type="submit" class="btn btn-primary col-12" @click="send" :disabled="loading">
            ورود
            <span class="spinner-border spinner-border-sm" v-show="loading"></span>
          </button>
        </div>
        <p class="text-center mt-2 m-0 small-text">حساب کاربری ندارید؟ <router-link to="/dashboard/register">ثبت
            نام</router-link>
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import { inject, ref } from 'vue';
import axios from 'axios';
import router from '@/router';
export default {

  setup() {
    const loading = ref(false);
    const toast = inject("toast");
    const data = ref({
      mobile: ''
    });
    const valid_password = ref('');
    const valid_mobile = ref('');

    function check() {

      const number = data.value.mobile
      if (number.length == 11) {
        for (var i = 0; i < 11; i++) {
          if (!(number[i] >= 0 && number[i] <= 9)) {
            valid_mobile.value = "is-invalid";
            return;
          }
        }
        valid_mobile.value = "is-valid";
      } else {
        valid_mobile.value = "is-invalid";
      }
      if (data.value.password == null ||
        data.value.password == '') {
        valid_password.value = "is-invalid";
      } else {
        valid_password.value = "is-valid";
      }




    }

    function send() {
      if (valid_password.value == "is-valid" &&
        valid_mobile.value == "is-valid") {
        loading.value = true;
        axios.post('/user/login', data.value)
          .then(function (response) {
            loading.value = false;
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("tokenExpire", response.data.expire);
            axios.defaults.headers.common['Authorization'] = JSON.stringify({ admin: localStorage.getItem("tokenAdmin"), user: localStorage.getItem("token") });
            toast.success("ورود موفق به پنل");
            router.push('/dashboard')
          })
          .catch(function (error) {
            toast.error("شماره موبایل یا رمز عبور اشتباه است");
            loading.value = false;
          })
      }
    }

    return { data, send, valid_password, valid_mobile, loading, check }
  }

}
</script>

<style scoped>
.register {
  width: 500px;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);

}

.small-text {
  font-size: 15px;
}

.container {
  height: 100%;
  position: relative;
}

.logo {
  display: block;
  height: 30px;
}

@media screen and (max-width: 600px) {
  .register {
    width: 90%;
  }
}
</style>