<template>
    <div>

        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
            جدید +
        </button>


        <div class="text-center mt-5" v-if="get_data_loading">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <div class="row" v-else>
            <div class="mt-3 col-md-12" v-for="server in servers" :key="server">
                <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">
                    <div>
                        {{ server.ip }}
                        <span class="badge rounded-pill bg-success me-1" v-if="server.active == 1">فعال</span>
                        <span class="badge rounded-pill bg-danger me-1" v-if="server.active == 0">غیر فعال</span>
                        <br>
                        <small>{{ server.gpu_value }}G:{{ server.gpu.name }}-{{ server.cpu_value }}C:{{ server.cpu.name
                        }}-{{ server.ram_value }}R:{{ server.ram.name }}-{{ server.disk_value }}D:{{
    server.disk.name }}</small>
                    </div>
                    <div class="me-auto">
                        <router-link class="btn btn-primary" :to="'/admin/servers/' + server._id">مدیریت</router-link>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="exampleModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
                        <h5 class="modal-title" id="exampleModalLabel">سرور جدید</h5>
                    </div>
                    <div class="modal-body">

                        <div dir="ltr">

                            <div class="mb-2">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                        value="jupyter" v-model="data.type" checked>
                                    <label class="form-check-label" for="inlineRadio1">jupyter Server</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                        value="server" v-model="data.type">
                                    <label class="form-check-label" for="inlineRadio2">GPU Server</label>
                                </div>
                            </div>

                            <div class="row" dir="ltr">
                                <div class="col-md-12 m-0 mb-2" dir="rtl">
                                    <input type="text" class="form-control" placeholder="ip" v-model="data.ip">
                                </div>
                                <div class="col-md-6 m-0 mb-2" dir="rtl">
                                    <input type="text" class="form-control" placeholder="username" v-model="data.username">
                                </div>
                                <div class="col-md-6 m-0 mb-2" dir="rtl">
                                    <input type="text" class="form-control" placeholder="password" v-model="data.password">
                                </div>
                            </div>

                            CPU
                            <div class="input-group row m-0 mb-2">
                                <select class="form-select col-md-6" v-model="data.cpu">
                                    <option v-for="hardware in hardwares.cpu" :key="hardware" :value="hardware._id">{{
                                        hardware.name }}</option>
                                </select>
                                <div class="col-md-6">
                                    <input type="number" class="form-control" placeholder="میزان" v-model="data.cpu_value">
                                </div>
                            </div>

                            GPU
                            <div class="input-group row m-0 mb-2">
                                <select class="form-select col-md-6" v-model="data.gpu">
                                    <option v-for="hardware in hardwares.gpu" :key="hardware" :value="hardware._id">{{
                                        hardware.name }}</option>
                                </select>
                                <div class="col-md-6">
                                    <input type="number" class="form-control" placeholder="میزان" v-model="data.gpu_value">
                                </div>
                                <div v-for="value in data.gpu_value" :key="value">
                                    <div class="col-md-12 ">
                                        <input type="text" class="form-control mt-2" :placeholder="'path for GPU ' + value" v-model="data.server_gpu_path[value - 1]">
                                    </div>
                                </div>
                            </div>

                            Ram
                            <div class="input-group row m-0 mb-2">
                                <select class="form-select col-md-6" v-model="data.ram">
                                    <option v-for="hardware in hardwares.ram" :key="hardware" :value="hardware._id">{{
                                        hardware.name }}</option>
                                </select>
                                <div class="col-md-6">
                                    <input type="number" class="form-control" placeholder="میزان" v-model="data.ram_value">
                                </div>
                            </div>

                            Disk
                            <div class="input-group row m-0 mb-2">
                                <select class="form-select col-md-6" v-model="data.disk">
                                    <option v-for="hardware in hardwares.disk" :key="hardware" :value="hardware._id">{{
                                        hardware.name }}</option>
                                </select>
                                <div class="col-md-6">
                                    <input type="number" class="form-control" placeholder="میزان" v-model="data.disk_value">
                                </div>
                            </div>

                            <div class="form-check form-check mt-2">
                                <input class="form-check-input" type="checkbox" checked v-model="data.active">
                                <label class="form-check-label" for="inlineCheckbox1">فعال</label>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="send()" :disabled="loading">
                            افزودن
                            <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted, watch } from '@vue/runtime-core';
export default {
    setup() {
        const loading = ref(false);
        const get_data_loading = ref(true);
        const toast = inject("toast");
        const modal = ref();
        const servers = ref([]);
        const hardwares = ref({
            cpu: [],
            gpu: [],
            ram: [],
            disk: []
        });
        const data = ref({
            active: true,
            free: false,
            type: "jupyter",
            server_gpu_path: []
        });

        function send() {
            console.log(data.value)
            loading.value = true;
            axios.post('/admin/new-server', data.value)
                .then(function (response) {
                    getData()
                    loading.value = false;
                    toast.success("افزودن سرور با موفقیت انجام شد");
                    modal.value.click();
                    console.log(response.data);
                })
                .catch(function (error) {
                    toast.error("افزودن سرور با خطا مواجه شد");
                    loading.value = false;
                    modal.value.click();
                    console.log(error);
                })
        }
        function getData() {
            axios.post('/admin/get-servers', {})
                .then(function (response) {
                    servers.value = response.data
                    get_data_loading.value = false;
                })
                .catch(function (error) {
                    console.log(error);
                    get_data_loading.value = false;
                })
            axios.post('/admin/get-hardwares', {})
                .then(function (response) {
                    response.data.forEach(ha => {
                        if (ha.type == 'cpu') {
                            hardwares.value.cpu.push(ha)
                        } else if (ha.type == 'gpu') {
                            hardwares.value.gpu.push(ha)
                        } else if (ha.type == 'ram') {
                            hardwares.value.ram.push(ha)
                        } else if (ha.type == 'disk') {
                            hardwares.value.disk.push(ha)
                        }
                    });
                    get_data_loading.value = false;
                })
                .catch(function (error) {
                    console.log(error);
                    get_data_loading.value = false;
                })
        }
        onMounted(() => {
            getData()
        })

        watch(data, (dt) => {
            console.log(data.value.gpu_value)
        })

        return { get_data_loading, loading, modal, servers, hardwares, data, send }
    }

}
</script>
  
<style></style>