<template>
    <div>

        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
            ایمیج جدید +
        </button>


        <div class="text-center mt-5" v-if="get_data_loading">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <div class="row" v-else>
            <div class="mt-3 col-md-4" v-for="image in images" :key="image">
                <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">
                    {{ image.name }}
                    <span class="badge rounded-pill bg-success me-1" v-if="image.active == 1">فعال</span>
                    <span class="badge rounded-pill bg-danger me-1" v-if="image.active == 0">غیر فعال</span>
                    <button class="btn btn-primary me-auto" data-bs-toggle="modal" data-bs-target="#edit"
                        @click="getImage(image._id)">ویرایش</button>
                </div>
            </div>
        </div>


        <div class="modal fade" id="exampleModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
                        <h5 class="modal-title" id="exampleModalLabel">ایمیج جدید</h5>
                    </div>
                    <div class="modal-body">

                        <div>
                            <div dir="ltr">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="newtype" id="inlineRadio1"
                                        value="app" v-model="data.type">
                                    <label class="form-check-label" for="inlineRadio1">App</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="newtype" id="inlineRadio2"
                                        value="os" v-model="data.type">
                                    <label class="form-check-label" for="inlineRadio2">OS</label>
                                </div>
                            </div>
                            نام
                            <div class="col-md-12 m-0 mb-2">
                                <input type="text" class="form-control" placeholder="نام نمایش" v-model="data.name">
                            </div>
                            سیستم عامل
                            <select class="col-md-12 m-0 mb-2 form-select" v-model="data.os">
                                <option value="windows">Windows</option>
                                <option value="ubuntu">Ubuntu</option>
                            </select>
                            آدرس
                            <div class="col-md-12 m-0 mb-2">
                                <input type="text" class="form-control" placeholder="آدرس vm" v-model="data.address">
                            </div>
                            پورت
                            <div class="col-md-12 m-0 mb-2">
                                <input type="text" class="form-control" placeholder="پورت راه اندازی" v-model="data.port">
                            </div>
                            <div class="form-check form-check mt-2" dir="ltr">
                                <input class="form-check-input" type="checkbox" checked v-model="data.active">
                                <label class="form-check-label" for="inlineCheckbox1">فعال</label>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="send()" :disabled="loading">
                            افزودن
                            <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--Edit Modal-->
        <div class="modal fade" id="edit" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" ref="editmodal"></button>
                        <h5 class="modal-title" id="exampleModalLabel">ویرایش</h5>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div dir="ltr">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                        value="app" v-model="edit_data.type" checked>
                                    <label class="form-check-label" for="inlineRadio1">App</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                        value="os" v-model="edit_data.type">
                                    <label class="form-check-label" for="inlineRadio2">OS</label>
                                </div>
                            </div>
                            نام
                            <div class="col-md-12 m-0 mb-2">
                                <input type="text" class="form-control" placeholder="نام نمایش" v-model="edit_data.name">
                            </div>
                            سیستم عامل
                            <select class="col-md-12 m-0 mb-2 form-select" v-model="edit_data.os">
                                <option value="windows" selected>Windows</option>
                                <option value="ubuntu">Ubuntu</option>
                            </select>
                            آدرس
                            <div class="col-md-12 m-0 mb-2">
                                <input type="text" class="form-control" placeholder="آدرس vm" v-model="edit_data.address">
                            </div>
                            پورت
                            <div class="col-md-12 m-0 mb-2">
                                <input type="text" class="form-control" placeholder="پورت راه اندازی" v-model="edit_data.port">
                            </div>
                            <div class="form-check form-check mt-2" dir="ltr">
                                <input class="form-check-input" type="checkbox" checked v-model="edit_data.active">
                                <label class="form-check-label" for="inlineCheckbox1">فعال</label>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="edit()" :disabled="loading">
                            ویرایش
                            <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
    
<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
export default {
    setup() {
        const loading = ref(false);
        const get_data_loading = ref(true);
        const edit_data_loading = ref(false);
        const toast = inject("toast");
        const modal = ref();
        const editmodal = ref();
        const images = ref([]);
        const edit_data = ref({})
        const data = ref({
            active: true,
            type: 'os'
        });

        function send() {
            loading.value = true;
            axios.post('/admin/new-image', data.value)
                .then(function (response) {
                    getData()
                    loading.value = false;
                    toast.success("افزودن ایمیج با موفقیت انجام شد");
                    modal.value.click();
                    console.log(response.data);
                })
                .catch(function (error) {
                    toast.error("افزودن ایمیج با خطا مواجه شد");
                    loading.value = false;
                    modal.value.click();
                    console.log(error);
                })
        }

        function edit() {
            loading.value = true;
            axios.post('/admin/edit-image', edit_data.value)
                .then(function (response) {
                    getData()
                    loading.value = false;
                    toast.success("ویرایش ایمیج با موفقیت انجام شد");
                    editmodal.value.click();
                })
                .catch(function (error) {
                    toast.error("ویرایش ایمیج با خطا مواجه شد");
                    loading.value = false;
                    editmodal.value.click();
                    console.log(error);
                })
        }

        function getData() {
            axios.post('/admin/get-images', {})
                .then(function (response) {
                    images.value = response.data
                    get_data_loading.value = false;
                })
                .catch(function (error) {
                    console.log(error);
                    get_data_loading.value = false;
                })
        }

        function getImage(id) {
            edit_data_loading.value = true;
            axios.post('/admin/get-image', { id: id })
                .then(function (response) {
                    edit_data.value = response.data
                    edit_data_loading.value = false;
                })
                .catch(function (error) {
                    console.log(error);
                    edit_data_loading.value = false;
                })
        }
        onMounted(() => {
            getData()
        })

        return { get_data_loading, loading, modal, images, data, send, edit, edit_data_loading, edit_data, getImage, editmodal }
    }

}
</script>
    
<style></style>