<template>
  <div>
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
      Ip جدید
    </button>

    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div v-else>
      <div class="mt-3" v-for="ip in ips" :key="ip">
        <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">{{ ip.address }}
          <router-link class="btn btn-primary me-auto" :to="'/admin/routers/router/' + ip._id">Nats</router-link>
        </div>
      </div>
    </div>


    <div class="modal fade" id="exampleModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">آی پی جدید</h5>
          </div>
          <div class="modal-body">

            <div class="row" dir="ltr">
              <div class="col-md-12 mt-2">
                <input type="text" class="form-control" placeholder="ip" v-model="data.address">
              </div>

              <div class="form-check ms-3 mt-2">
                <input class="form-check-input" type="checkbox" value="" id="flexCheck" v-model="data.dedicated">
                <label class="form-check-label" for="flexCheck">
                  Dedicated
                </label>
              </div>

              <div class="form-check ms-3 mt-2">
                <input class="form-check-input" type="checkbox" value="" id="flexCheck" checked v-model="data.status">
                <label class="form-check-label" for="flexCheck">
                  Status
                </label>
              </div>

            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="send()" :disabled="loading">
              ذخیره
              <span class="spinner-border spinner-border-sm" v-show="loading"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute()
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const modal = ref();
    const ips = ref([]);
    const data = ref({
      router: route.params.id,
      address: '',
      dedicated: false,
      status: true
    })

    function send() {
      loading.value = true;
      axios.post('/admin/new-ip', data.value)
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("افزودن IP با موفقیت انجام شد");
          modal.value.click();
          console.log(response.data);
        })
        .catch(function (error) {
          toast.error("افزودن IP با خطا مواجه شد");
          loading.value = false;
          modal.value.click();
          console.log(error);
        })
    }
    function getData() {
      axios.post('/admin/get-ips', {
        _id: route.params.id
      })
        .then(function (response) {
          ips.value = response.data
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }
    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, modal, ips, data, send }
  }
}
</script>

<style>

</style>