import Main from '@/components/admin/AdminMain.vue';
import Services from '@/pages/admin/Services.vue';
import ManageService from '@/pages/admin/ManageService.vue';
import Routers from '@/pages/admin/Routers.vue';
import Router from '@/pages/admin/Router.vue';
import Servers from '@/pages/admin/Servers.vue';
import ManageServers from '@/pages/admin/ManageServers.vue';
import Plans from '@/pages/admin/Plans.vue';
import Hardwares from '@/pages/admin/Hardwares.vue';
import Storages from '@/pages/admin/Storages.vue';
import Images from '@/pages/admin/Images.vue';
import Users from '@/pages/admin/Users.vue';
import ManageUser from '@/pages/admin/ManageUser.vue';
import Payments from '@/pages/admin/Payments.vue';
import Logs from '@/pages/admin/Logs.vue';
import Supports from '@/pages/admin/Supports.vue';
import ManageTicket from '@/pages/admin/ManageTicket.vue';
import Settings from '@/pages/admin/Settings.vue';
import StoragePlan from '@/pages/admin/StoragePlan.vue';

export default () => {
    const routes = [
        { path: '', components: { admin: Main } },
        { path: 'services', children: [
            { path: '', components: { admin: Services }  },
            { path: ':id', components: { admin: ManageService } },
        ]},
        { path: 'servers', children: [
            { path: '', components: { admin: Servers }  },
            { path: ':id', components: { admin: ManageServers } },
        ] },
        { path: 'plans', components: { admin: Plans } },
        { path: 'hardwares', components: { admin: Hardwares } },
        { path: 'storages', components: { admin: Storages } },
        { path: 'images', components: { admin: Images } },
        { path: 'storage-plan', components: { admin: StoragePlan } },
        { path: 'routers',  children: [
            { path: '', components: { admin: Routers } },
            { path: 'router/:id', components: { admin: Router } },
        ]},
        { path: 'users', children: [
            { path: '', components: { admin: Users }  },
            { path: ':id', components: { admin: ManageUser } },
        ]  },
        { path: 'payments', components: { admin: Payments } },
        { path: 'supports',  children: [
            { path: '', components: { admin: Supports }  },
            { path: ':id', components: { admin: ManageTicket } },
        ]},
        { path: 'logs', components: { admin: Logs } },
        { path: 'settings', components: { admin: Settings } },
    ]
    return routes
}