<template>
  <div>
    <p>dashboard main</p>

  </div>
</template>

<script>
import { ref, inject } from "vue";
import axios from 'axios';

export default {

  setup() {

    const loading = ref(false)
    const toast = inject("toast");

    function send() {
      
    }

    return { send, loading }
  }
}
</script>
<style>
.dropdown-item {
  cursor: pointer;
}
</style>