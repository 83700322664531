<template>
  <div>

    <div class="mt-3 col-md-12">
      <div class="shadow p-3 mb-2 bg-body rounded d-flex align-items-center">
        <input type="search" class="form-control" id="exampleInputEmail1" placeholder="جست و جوی کاربر ..."
          v-model="search">
      </div>
    </div>

    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div class="row" v-else>
      <div class="mt-3 col-md-12" v-for="user in users" :key="user">
        <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">
          {{ user.name }}
          <span class="badge rounded-pill bg-warning me-1" v-if="user.level == 0">اولیه</span>
          <span class="badge rounded-pill bg-success me-1" v-if="user.level == 1">تکمیل</span>
          <span class="badge rounded-pill bg-danger me-1" v-if="user.level == 3">قفل</span>
          <div class="me-auto">
            <router-link class="btn btn-primary" :to="'/admin/users/' + user._id">مدیریت</router-link>
          </div>
        </div>
      </div>
    </div>

    <nav aria-label="Page navigation" dir="ltr">
      <ul class="pagination justify-content-center">
        <li v-for="p in page" :key="p" class="page-item">
          <a v-if="p == active_page" class="page-link active" href="#" @click="getData(p)">{{ p }}</a>
          <a v-else class="page-link" href="#" @click="getData(p)">{{ p }}</a>
        </li>
      </ul>
    </nav>

  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted, watch } from '@vue/runtime-core';
export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const modal = ref();
    const plans = ref([]);
    const users = ref([]);
    const price = ref(0);
    const data = ref({});
    const page = ref(1);
    const active_page = ref(0);
    const search = ref('')

    function getData(page_id, search = '') {
      active_page.value = page_id;
      axios.post('/admin/get-users', { page: page_id, search })
        .then(function (response) {
          users.value = response.data.users
          if (response.data.page != null) {
            page.value = parseInt(response.data.page / 20) + 1;
          }
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }
    onMounted(() => {
      getData(1)
    })

    watch(search, (sr) => {
      getData(1, sr)
    })

    return { get_data_loading, loading, modal, plans, users, data, page, active_page, getData, search }
  }

}
</script>

<style></style>