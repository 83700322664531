<template>
  <div>
    <div class="shadow p-3 mb-3 bg-body rounded">
      <div class="row g-3">
        <div class="col-md-6">
          <label for="mobile" class="form-label">شماره موبایل</label>
          <input type="text" class="form-control" id="mobile" v-model="data.mobile" />
        </div>
        <div class="col-md-6">
          <label for="name" class="form-label">نام و نام خانوادگی</label>
          <input type="text" class="form-control" id="name" v-model="data.name">
        </div>
      </div>
    </div>
    مشخصات تکمیلی
    <div class="shadow p-3 mb-3 mt-2 bg-body rounded">
      <div class="row g-3">
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">ایمیل</label>
          <input type="text" class="form-control" id="inputEmail4" placeholder="ایمیل خودرا وارد کنید"
            v-model="data.email">
        </div>
        <div class="col-md-6">
          <label for="nationalID" class="form-label">کد ملی</label>
          <input type="text" class="form-control" id="nationalID" placeholder="کد ملی خودرا وارد کنید"
            v-model="data.nationalid">
        </div>
        <div class="col-8">
          <label for="address" class="form-label">آدرس</label>
          <input type="text" class="form-control" id="address" placeholder="آدرس خود را وارد کنید" v-model="data.address">
        </div>
        <div class="col-4">
          <label for="city" class="form-label">شهر</label>
          <input type="text" class="form-control" id="city" placeholder="شهر خود را وارد کنید" v-model="data.city">
        </div>
        <div class="col-md-4">
          <label for="postalcode" class="form-label">کد پستی</label>
          <input type="text" class="form-control" id="postalcode" placeholder="کد پستی خود را وارد کنید"
            v-model="data.postalcode">
        </div>
        <div class="col-md-4">
          <label for="level" class="form-label">وضعیت حساب</label>
          <select class="form-select" id="level" v-model="data.level">
            <option value="0">ثبت اولیه</option>
            <option value="1">اطلاعات تکمیل شده</option>
            <option value="2">قفل کردن</option>
          </select>
        </div>

        <div class="col-12">
          <button type="submit" class="btn btn-primary" @click="send">ویرایش مشخصات</button>
        </div>
      </div>
    </div>

    کیف پول
    <div class="shadow p-3 mb-3 bg-body rounded">
      <div class="row g-3">
        <div class="col-md-6">
          <label for="wallet" class="form-label">موجودی</label>
          <input type="text" class="form-control" id="wallet" v-model="data.wallet" disabled />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
export default {
  setup() {
    const route = useRoute()
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const data = ref({});

    function send() {
      delete data.wallet; // remove wallet value
      loading.value = true;
      axios.post('/admin/edit-user', data.value)
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("ثبت مشخصات  با موفقیت انجام شد");
        })
        .catch(function (error) {
          toast.error("ثبت مشخصات با مشکل مواجه شد");
          loading.value = false;
          console.log(error);
        })

    }

    function getData() {
      axios.post('/admin/get-user', { id: route.params.id })
        .then(function (response) {
          data.value = response.data;
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }
    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, send, data }
  }
}
</script>

<style></style>