<template>
    <div>

        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
            سرور جدید +
        </button>


        <div class="text-center mt-5" v-if="get_data_loading">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <div class="row" v-else>
            <div class="mt-3 col-md-12" v-for="service in services" :key="service">
                <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">
                    <div>
                        {{ service.plan.name }}
                        <span class="badge rounded-pill bg-warning" v-if="service.status == 'pending'">انتظار</span>
                        <span class="badge rounded-pill bg-warning" v-if="service.status == 'queue'">صف</span>
                        <span class="badge rounded-pill bg-success" v-if="service.status == 'active'">فعال</span>
                        <span class="badge rounded-pill bg-danger" v-if="service.status == 'error'">خطا</span>
                        <span class="badge rounded-pill bg-primary" v-if="service.status == 'finished'">پایان یافته</span>
                        <br>
                        <small>G:{{ service.plan.gpu.name }}-C:{{ service.plan.cpu_value }}-R:{{ service.plan.ram_value
                        }}-D:{{ service.plan.disk_value }}</small>
                    </div>

                    <div class="me-auto">
                        <box-icon class="pointer" name='play' animation='tada-hover' size="xl" title="روشن کردن"
                            color='#455A64' @click="start(service._id)"></box-icon>
                        <box-icon class="pointer" name='stop' animation='tada-hover' size="xl" title="خاموش کردن"
                            color='#455A64' @click="stop(service._id)"></box-icon>
                        <box-icon class="pointer" name='rotate-right' animation='spin-hover' size="cssSize"
                            title="ری استارت" color='#455A64' @click="restart(service._id)"></box-icon>
                        <box-icon class="pointer" name='link' animation='tada-hover' size="xl" title="اتصال" color='#455A64'
                            @click="connection = { username: service.username, password: service.password }"
                            data-bs-toggle="modal" data-bs-target="#connect"></box-icon>
                        <box-icon class="pointer" name='dots-vertical-rounded' size="cssSize" title="گزینه ها"
                            color='#455A64' data-bs-toggle="dropdown"></box-icon>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" @click="getNats(service._id)" data-bs-toggle="modal"
                                    data-bs-target="#network">فایروال</a></li>
                            <li><a class="dropdown-item" @click="remove(service._id)">حذف سرویس</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="exampleModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
                        <h5 class="modal-title" id="exampleModalLabel">سرویس جدید</h5>
                    </div>
                    <div class="modal-body">

                        <div class="row m-0 mt-2" v-for="plan in plans" :key="plan">
                            <input type="radio" class="btn-check" name="options-outlined" :id="plan._id" :value="plan._id"
                                @change="change_price(plan.price)" v-model="data.plan">
                            <label class="btn btn-outline-success" :for="plan._id">{{ plan.name }}</label>
                        </div>

                        <div class="col-md-12 mt-2">
                            <label class="form-label">انتخاب سیستم عامل</label>
                            <select class="form-select" v-model="data.image">
                                <option disabled selected value> -- یک سیستم عامل انتخاب کنید -- </option>
                                <option v-for="image in images" :key="image" :value="image._id">{{ image.name }}</option>
                            </select>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <p class="ms-auto">هر ساعت {{ price.toLocaleString() }} تومان</p>
                        <button type="button" class="btn btn-primary" @click="send()" :disabled="loading">
                            سفارش
                            <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <!--Network-->
        <div class="modal fade" id="network" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
                        <h5 class="modal-title" id="exampleModalLabel">فایروال</h5>
                    </div>
                    <div class="modal-body">

                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label class="form-label">پورت</label>
                                <input type="number" class="form-control" v-model="nat_data.port">
                            </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-label">پرتکل</label>
                                <select class="form-select" v-model="nat_data.protocol">
                                    <option value="tcp" selected>TCP</option>
                                    <option value="udp">UDP</option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <button type="button" class="btn btn-primary add-roll" @click="newNat()"
                                    :disabled="loading">
                                    افزودن
                                    <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                                </button>
                            </div>
                        </div>
                        <div class="mt-2">
                            <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center" v-for="nat in nats"
                                :key="nat">
                                {{ nat.protocol }}
                                <a target="_blank" :href="'http://' + nat.public + ':' + nat.public_port"
                                    class="me-2 ms-2">{{
                                        nat.public }}:{{ nat.public_port }}</a> -> {{ nat.local_port }}
                                <box-icon name='trash' class="me-auto trash" @click="deleteNat(nat._id)"
                                    :disabled="loading"></box-icon>
                                <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!--Connect-->
        <div class="modal fade" id="connect" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
                        <h5 class="modal-title" id="exampleModalLabel">اتصال به سرویس</h5>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label class="form-label">Username</label>
                            <input type="text" class="form-control" readonly :value="connection.username">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Password</label>
                            <input type="text" class="form-control" readonly :value="connection.password">
                        </div>

                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
  
<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
export default {
    setup() {
        const loading = ref(false);
        const get_data_loading = ref(true);
        const toast = inject("toast");
        const modal = ref();
        const plans = ref([]);
        const services = ref([]);
        const price = ref(0);
        const data = ref({
            service_type: "server"
        });
        const images = ref([]);
        const nats = ref([]);
        const nat_id = ref();
        const nat_data = ref({});
        const connection = ref({});
        function change_price(p) {
            price.value = p;
        }

        function send() {
            loading.value = true;
            axios.post('/dashboard/new-service', data.value)
                .then(function (response) {
                    getData()
                    loading.value = false;
                    toast.success("افزودن سرویس با موفقیت انجام شد");
                    modal.value.click();
                })
                .catch(function (error) {
                    toast.error("افزودن سرویس با خطا مواجه شد");
                    loading.value = false;
                    modal.value.click();
                    console.log(error);
                })
        }

        function start(id) {
            loading.value = true;
            axios.post('/dashboard/start-service', {
                id: id
            })
                .then(function (response) {
                    getData()
                    loading.value = false;
                    toast.success("شروع سرویس با موفقیت انجام شد");
                })
                .catch(function (error) {
                    toast.error("شروع سرویس با خطا مواجه شد");
                    loading.value = false;
                    console.log(error);
                })
        }

        function stop(id) {
            loading.value = true;
            axios.post('/dashboard/stop-service', {
                id: id
            })
                .then(function (response) {
                    getData()
                    loading.value = false;
                    toast.success("توقف سرویس با موفقیت انجام شد");
                })
                .catch(function (error) {
                    toast.error("توقف سرویس با خطا مواجه شد");
                    loading.value = false;
                    console.log(error);
                })
        }

        function restart(id) {
            loading.value = true;
            axios.post('/dashboard/restart-service', {
                id: id
            })
                .then(function (response) {
                    getData()
                    loading.value = false;
                    toast.success("شروع مجدد سرویس با موفقیت انجام شد");
                })
                .catch(function (error) {
                    toast.error("شروع مجدد سرویس با خطا مواجه شد");
                    loading.value = false;
                    console.log(error);
                })
        }

        function remove(id) {
            loading.value = true;
            axios.post('/dashboard/remove-service', {
                id: id
            })
                .then(function (response) {
                    getData()
                    loading.value = false;
                    toast.success(" حذف سرویس با موفقیت انجام شد");
                })
                .catch(function (error) {
                    toast.error("حذف سرویس با خطا مواجه شد");
                    loading.value = false;
                    console.log(error);
                })
        }

        function closeModal() {
            modal.value.click();
        }

        function newNat() {
            loading.value = true;
            axios.post('/dashboard/service-new-nat', {
                id: nat_id.value,
                protocol: nat_data.value.protocol,
                port: nat_data.value.port,
            })
                .then(function (response) {
                    getNats(nat_id.value)
                    loading.value = false;
                    toast.success("فایروال بروزرسانی شد");
                })
                .catch(function (error) {
                    toast.error("خطا در بروزرسانی فایروال");
                    loading.value = false;
                    console.log(error);
                })
        }

        function getNats(id) {
            nat_id.value = id;
            axios.post('/dashboard/service-get-nats', { id: id })
                .then(function (response) {
                    loading.value = false;
                    nats.value = response.data;
                })
                .catch(function (error) {
                    loading.value = false;
                })
        }

        function deleteNat(id) {
            loading.value = true;
            axios.post('/dashboard/service-delete-nat', { id: id })
                .then(function (response) {
                    loading.value = false;
                    toast.success("فایروال بروزرسانی شد");
                    getNats(nat_id.value)
                })
                .catch(function (error) {
                    loading.value = false;
                    toast.error("خطا در بروزرسانی فایروال");
                })
        }


        function getData() {
            axios.post('/dashboard/get-services', { type: "server" })
                .then(function (response) {
                    services.value = response.data
                    get_data_loading.value = false;
                })
                .catch(function (error) {
                    console.log(error);
                    get_data_loading.value = false;
                })
            axios.post('/dashboard/get-plans', { type: "server" })
                .then(function (response) {
                    plans.value = response.data;
                    get_data_loading.value = false;
                })
                .catch(function (error) {
                    console.log(error);
                    get_data_loading.value = false;
                })

            axios.post('/dashboard/get-images', {})
                .then(function (response) {
                    images.value = response.data;
                    get_data_loading.value = false;
                })
                .catch(function (error) {
                    console.log(error);
                    get_data_loading.value = false;
                })
        }


        onMounted(() => {
            getData()
        })

        return { get_data_loading, loading, modal, plans, send, services, price, change_price, data, restart, remove, closeModal, images, newNat, getNats, nats, nat_data, deleteNat, stop, start, connection }
    }

}
</script>
  
<style scoped>
.pointer {
    cursor: pointer;
    height: 30px;
    margin-right: 2px;
}

.add-roll {
    width: 100%;
    margin-top: 32px;
}

.trash {
    cursor: pointer;
}
</style>