import Main from '@/components/users/DashboardMain.vue';
import Ip from '@/pages/users/Ip.vue';
import ManageIp from '@/pages/users/ManageIp.vue';
import Services from '@/pages/users/Services.vue';
import Servers from '@/pages/users/Servers.vue';
import Storages from '@/pages/users/Storages.vue';
import Wallet from '@/pages/users/Wallet.vue';
import Support from '@/pages/users/Support.vue';
import ManageSupport from '@/pages/users/ManageSupport.vue';
import Profile from '@/pages/users/Profile.vue';

export default () => {
    const routes = [
        { path: '', components: { dashboard: Main } },
        { path: 'services', components: { dashboard: Services } },
        { path: 'servers', components: { dashboard: Servers } },
        { path: 'storages', components: { dashboard: Storages } },
        { path: 'ip', children: [
            { path: '', components: { dashboard: Ip } },
            { path: 'manage/:id', components: { dashboard: ManageIp } },
        ] },
        { path: 'wallet', components: { dashboard: Wallet } },
        { path: 'support', children: [
            { path: '', components: { dashboard: Support } },
            { path: ':id', components: { dashboard: ManageSupport } },
        ] },
        { path: 'profile', components: { dashboard: Profile } },

    ]
    return routes
}