<template>
  <div>

    <div class="mt-3">
      <div class="shadow p-3 mb-3 bg-body rounded row">
        <div class="col-md-6 mt-1">
          موجودی: {{ wallet.toLocaleString() }} تومان
        </div>

        <div class="col-md-4">
          <input type="number" class="form-control" placeholder="مقدار مورد نظر (تومان)" v-model="data.value">
        </div>

        <div class="col-md-2">
          <button class="btn btn-primary" @click="send">افزایش موجودی</button>
        </div>

      </div>
    </div>
    تراکنش ها
    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div v-else>
      <div class="mt-3" v-for="transaction in transactions" :key="transaction">
        <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">
          <box-icon name='up-arrow-alt' v-if="transaction.type == 'paas'" color='#E74C3C' size="md"></box-icon>
          <box-icon name='down-arrow-alt' v-else-if="transaction.type == 'input'" color='#27AE60' size="md"></box-icon>
          <div>{{ transaction.value.toLocaleString() }} تومان
            <span class="badge rounded-pill bg-warning" v-if="transaction.status == 'pending'">در انتظار</span>
            <span class="badge rounded-pill bg-success" v-else-if="transaction.status == 'complete'">انجام شده</span>
            <br>
            <small>{{ transaction.createdAt }}</small>
          </div>
          <div class="me-auto">
            <box-icon name='file' class="pointer" size="cssSize" title="جزئیات" color='#455A64' data-bs-toggle="modal"
              data-bs-target="#invoice" @click="getTransaction(transaction._id)"></box-icon>
          </div>
        </div>
      </div>

      <nav aria-label="Page navigation" dir="ltr">
        <ul class="pagination justify-content-center">
          <li v-for="p in page" :key="p" class="page-item">
            <a v-if="p == active_page" class="page-link active" href="#" @click="getData(p)">{{ p }}</a>
            <a v-else class="page-link" href="#" @click="getData(p)">{{ p }}</a>
          </li>
        </ul>
      </nav>
    </div>

    <div class="modal fade" id="invoice" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">جزئیات</h5>
          </div>
          <div class="modal-body">
            <p>وضعیت:
              <span class="badge rounded-pill bg-warning" v-if="transaction_data.status == 'pending'">در انتظار</span>
              <span class="badge rounded-pill bg-success" v-if="transaction_data.status == 'complete'">انجام شده</span>
            </p>
            <p>تاریخ:
              <span>{{ transaction_data.createdAt }}</span>
            </p>
            <p>نوع:
              <span v-if="transaction_data.type == 'paas'">پرداخت ساعتی</span>
              <span v-if="transaction_data.type == 'input'">شارژ کیف پول</span>
            </p>
            <p>مبلغ:
              <span>{{ transaction_data.value.toLocaleString() }}</span>
              تومان
            </p>
            <p v-if="transaction_data.service_type != null">
              سرویس:
              <span v-if="transaction_data.service_type == 'storage'">دیسک ابری</span>
              <span v-if="transaction_data.service_type == 'service'">سرویس پردازشی</span>
            </p>

            <p v-if="transaction_data.type == 'input'">
              شماره پیگیری: {{ transaction_data.tracking_id }}
            </p>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const modal = ref();
    const transactions = ref([]);
    const wallet = ref(0);
    const data = ref({});
    const transaction_data = ref({
      value: 0
    });
    const page = ref(1);
    const active_page = ref(0);

    function send() {
      loading.value = true;
      axios.post('/dashboard/new-bank-transaction', data.value)
        .then(function (response) {
          getData(1)
          loading.value = false;
          toast.success("افزایش موجودی با موفقیت انجام شد");
        })
        .catch(function (error) {
          toast.error("افزایش موجودی با خطا مواجه شد");
          loading.value = false;
          console.log(error);
        })
    }
    function getData(page_id) {
      active_page.value = page_id;
      axios.post('/dashboard/get-wallet', { page: page_id })
        .then(function (response) {
          transactions.value = response.data.transactions;
          wallet.value = response.data.wallet;
          if (response.data.page != null) {
            page.value = parseInt(response.data.page / 20) + 1;
          }
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }
    function getTransaction(id) {
      axios.post('/dashboard/get-transaction', { id: id })
        .then(function (response) {
          transaction_data.value = response.data;
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }
    onMounted(() => {
      getData(1)
    })

    return { get_data_loading, loading, modal, transactions, send, wallet, data, transaction_data, getTransaction, page, active_page, getData }
  }

}
</script>

<style scoped>
.pointer {
  cursor: pointer;
  height: 30px;
  margin-right: 2px;
}
</style>