<template>
    <div>
        <div v-if="data.status == 0">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                ایجاد کانکشن
            </button>
        </div>
        <div v-else>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                رول جدید +
            </button>
        </div>

        <div class="modal fade" id="exampleModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
                        <h5 class="modal-title" id="exampleModalLabel">ایجاد کانکشن</h5>
                    </div>
                    <div class="modal-body">
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="new_connection()" :disabled="loading">
                            ایجاد کانکشن
                            <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
export default {
    setup() {
        const route = useRoute()
        const loading = ref(false);
        const get_data_loading = ref(true);
        const toast = inject("toast");
        const modal = ref();
        const data = ref({});

        function new_connection(){

        }

        function getData() {
            axios.post('/dashboard/get-service', {
                _id: route.params.id
            })
                .then(function (response) {
                    data.value = response.data
                    get_data_loading.value = false;
                })
                .catch(function (error) {
                    console.log(error);
                    get_data_loading.value = false;
                })
        }
        onMounted(() => {
            getData()
        })

        return { data, loading, new_connection }
    }
}
</script>

<style>

</style>