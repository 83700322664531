import { createRouter, createWebHistory } from 'vue-router'
import DashboardMain from '../pages/users/DashboardMain.vue'
import AdminMain from '../pages/admin/AdminMain.vue'
import users from './users'
import admin from './admin'
import Register from '@/pages/users/Register.vue';
import Login from '@/pages/users/Login.vue';
import AdminLogin from '@/pages/admin/Login.vue';
import NotFound from '@/pages/NotFound.vue';

const routes = [
  { path: '/dashboard', components: { main: DashboardMain }, children: users() },
  { path: '/dashboard/register', components: { main: Register } },
  { path: '/dashboard/login', components: { main: Login } },
  { path: '/admin', components: { main: AdminMain }, children: admin() },
  { path: '/admin/login/:token', components: { main: AdminLogin } },
  { path: '/:pathMatch(.*)*', components: { main: NotFound } },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched[0].path == "/dashboard") {
    if (localStorage.getItem("token") != null) {
      document.title = "Jupyto"
      return next();
    } else {
      localStorage.removeItem("token");
      next('/dashboard/login')
    }
  } else if (to.matched[0].path == "/admin") {
    if (localStorage.getItem("tokenAdmin") != null) {
        document.title = "Admin | Jupyto"
      return next();
    } else {
      localStorage.removeItem("tokanAdmin");
      next("/404")
    }

  } else {

    return next();
  }
})



export default router
