<template>
  <div v-if="verify">
    <nav class="navbar navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="https://jupyto.com">
          <img src="@/assets/images/logo.png" class="logo">
        </a>
      </div>
    </nav>
    <div class="container">
      <div class="shadow p-3 bg-body rounded register ">
        <h3 class="text-center mb-3">Login</h3>
        <div class="mb-3">
          <label for="phone" class="form-label">usename</label>
          <input type="text" :class="'form-control ' + valid_username" id="phone" v-model="data.username" @change="check">
        </div>

        <div class="mb-3">
          <label for="name" class="form-label">password</label>
          <input type="password" :class="'form-control ' + valid_password" id="name" v-model="data.password"
            @change="check">
        </div>

        <div class="col-12">
          <button type="submit" class="btn btn-primary col-12" @click="send" :disabled="loading">
            Login
            <span class="spinner-border spinner-border-sm" v-show="loading"></span>
          </button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import axios from 'axios';
import router from '@/router';
import { useRoute } from 'vue-router';
export default {

  setup() {
    const route = useRoute()
    const loading = ref(false);
    const toast = inject("toast");
    const data = ref({
      token: route.params.token
    });
    const verify = ref(false);
    const valid_password = ref('');
    const valid_username = ref('');

    function check() {

      if (data.value.username != null &&
        data.value.username != '') {
        valid_username.value = "is-valid";
      } else {
        valid_username.value = "is-invalid";
      }
      if (data.value.password == null ||
        data.value.password == '') {
        valid_password.value = "is-invalid";
      } else {
        valid_password.value = "is-valid";
      }

    }

    function send() {
      if (valid_password.value == "is-valid" &&
        valid_username.value == "is-valid") {
        loading.value = true;
        axios.post('/ad/login', data.value)
          .then(function (response) {
            loading.value = false;
            localStorage.setItem("tokenAdmin", response.data.token);
            localStorage.setItem("tokenAdminExpire", response.data.expire);
            axios.defaults.headers.common['Authorization'] = JSON.stringify({ admin: localStorage.getItem("tokenAdmin"), user: localStorage.getItem("token") });
            toast.success("ورود موفق به پنل");
            router.push('/admin')
          })
          .catch(function (error) {
            toast.error("error");
            loading.value = false;
          })
      }
    }

    onMounted(() => {
      axios.post('/ad/verify-token', {token: route.params.token})
        .then(function (response) {
          verify.value = true;
        })
        .catch(function (error) {
          console.log(error);
        })
    })

    return { data, send, valid_password, valid_username, loading, check, verify }
  }

}
</script>

<style scoped>
.register {
  width: 500px;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);

}

.small-text {
  font-size: 15px;
}

.container {
  height: 100%;
  position: relative;
}

.logo {
  display: block;
  height: 30px;
}

@media screen and (max-width: 600px) {
  .register {
    width: 90%;
  }
}
</style>