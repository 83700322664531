<template>
  <div>

    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div class="message-box" ref="box">
      <div class="mt-3" v-for="message in messages" :key="message">
        <div v-if="message.side == 0">
          <div class="shadow p-3 mb-3 rounded col-md-5 bg-primary bg-gradient text-white">
            <p v-if="message.type == 0" v-html="message.msg.replaceAll('\n', '<br>')"></p>
            <button v-else-if="message.type == 1" @click="getFile(message.src)">دریافت</button>
            <img :src="image">
            <small>{{ message.date }}</small>
          </div>
        </div>
        <div v-else>
          <div class="shadow p-3 mt-5 mb-3 rounded col-md-5 me-auto">
            <p v-html="message.msg.replaceAll('\n', '<br>')"></p>
            <small>{{ message.date }}</small>
          </div>
        </div>

      </div>
    </div>

    <div class=" mt-3">
      <div class="mb-3 d-flex align-items-center">
        <box-icon type='solid' name='send' @click="send()" class="ms-1 pointer"></box-icon>
        <textarea class="form-control" v-model="data.message" rows="2"></textarea>
        <label>
          <box-icon name='link-alt' class="me-1 pointer"></box-icon><input type="file" hidden @change="upload()"
            ref="inputFile">
        </label>
      </div>
    </div>


  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import fileDownload from 'js-file-download';

export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const route = useRoute()
    const modal = ref();
    const box = ref();
    const messages = ref([]);
    const data = ref({});
    const inputFile = ref();
    const image = ref('');

    function send() {
      loading.value = true;
      axios.post('/dashboard/ticket-new-message', {
        id: route.params.id,
        message: data.value.message,
        file: data.value.file
      })
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("پیام با موفقیت ارسال شد");
          data.value.message = "";
        })
        .catch(function (error) {
          toast.error("خطا در ارسال پیام");
          loading.value = false;
          console.log(error);
        })
    }

    function upload() {
      alert("upload")
      console.log(inputFile.value.files[0]);
      let formData = new FormData();
      formData.append("file", inputFile.value.files[0]);
      formData.append("id", route.params.id);
      axios.post('/dashboard/ticket-new-file', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("پیام با موفقیت ارسال شد");
          data.value.message = "";
        })
        .catch(function (error) {
          toast.error("خطا در ارسال پیام");
          loading.value = false;
          console.log(error);
        })
    }

    function getFile(id) {
      axios.post('/dashboard/get-ticket-file/' + id, {
        responseType: 'blob',
      })
        .then(function (response) {
          console.log(response.data)
          fileDownload(response.data, "image.jpg");
          console.log();
        })
        .catch(function (error) {

          console.log(error);
        })
    }


    function getData() {
      axios.post('/dashboard/get-ticket', { id: route.params.id })
        .then(function (response) {
          messages.value = response.data.messages
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }

    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, modal, send, messages, data, box, inputFile, upload, getFile, image }
  }

}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}


.message-box {
  height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>