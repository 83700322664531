<template>
  <div>

    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
      سرویس جدید +
    </button>


    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div class="row" v-else>
      <div class="mt-3 col-md-12" v-for="service in services" :key="service">
        <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">
          <div>
            {{ service.plan.name }}
            <span class="badge rounded-pill bg-warning" v-if="service.status == 'pending'">انتظار</span>
            <span class="badge rounded-pill bg-warning" v-if="service.status == 'queue'">صف</span>
            <span class="badge rounded-pill bg-success" v-if="service.status == 'active'">فعال</span>
            <span class="badge rounded-pill bg-danger" v-if="service.status == 'error'">خطا</span>
            <span class="badge rounded-pill bg-primary" v-if="service.status == 'finished'">پایان یافته</span>
            <br>
            <small>G:{{ service.plan.gpu.name }}-C:{{ service.plan.cpu_value }}-R:{{ service.plan.ram_value }}-D:{{
              service.plan.disk_value }}</small>
          </div>

          <div class="me-auto">
            <a target="_blank" :href="'https://' + service._id + '.co.jupyto.com?token=' + service.token"><box-icon
                class="pointer" name='link-external' animation='tada-hover' size="cssSize" title="لانچ نوت بوک"
                color='#455A64'></box-icon></a>
            <box-icon class="pointer" name='rotate-right' animation='spin-hover' size="cssSize" title="ری استارت"
              color='#455A64' @click="restart(service._id)"></box-icon>
            <box-icon class="pointer" name='dots-vertical-rounded' size="cssSize" title="گزینه ها" color='#455A64'
              data-bs-toggle="dropdown"></box-icon>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" @click="remove(service._id)">حذف سرویس</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="exampleModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">سرویس جدید</h5>
          </div>
          <div class="modal-body">

            <div class="row m-0 mt-2" v-for="plan in plans" :key="plan">
              <input type="radio" class="btn-check" name="options-outlined" :id="plan._id" :value="plan._id"
                @change="change_price(plan.price)" v-model="data.plan">
              <label class="btn btn-outline-success" :for="plan._id">{{ plan.name }}</label>
            </div>
            <div class="col-md-12 mt-2">
              <label class="form-label">انتخاب دیسک ابری(<router-link :to="'/dashboard/storages'"
                  @click="closeModal()">ساخت دیسک</router-link>)</label>
              <select class="form-select" v-model="data.storage">
                <option disabled selected value> -- یک دیسک انتخاب کنید -- </option>
                <option v-for="storage in user_storage" :key="storage" :value="storage._id">{{ storage.size }}GB</option>
              </select>
              <small>امکان ویرایش دیسک ابری پس از تحویل سرویس وجود نخواهد داشت</small>
            </div>

          </div>
          <div class="modal-footer">
            <p class="ms-auto">هر ساعت {{ price.toLocaleString() }} تومان</p>
            <button type="button" class="btn btn-primary" @click="send()" :disabled="loading">
              سفارش
              <span class="spinner-border spinner-border-sm" v-show="loading"></span>
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const modal = ref();
    const plans = ref([]);
    const services = ref([]);
    const user_storage = ref([]);
    const service_storage = ref({});
    const price = ref(0);
    const data = ref({
      service_type: "jupyter"
    });


    function change_price(p) {
      price.value = p;
    }

    function send() {
      loading.value = true;
      axios.post('/dashboard/new-service', data.value)
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("افزودن سرویس با موفقیت انجام شد");
          modal.value.click();
        })
        .catch(function (error) {
          toast.error("افزودن سرویس با خطا مواجه شد");
          loading.value = false;
          modal.value.click();
          console.log(error);
        })
    }

    function restart(id) {
      loading.value = true;
      axios.post('/dashboard/restart-service', {
        id: id
      })
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("شروع مجدد سرویس با موفقیت انجام شد");
          modal.value.click();
        })
        .catch(function (error) {
          toast.error("شروع مجدد سرویس با خطا مواجه شد");
          loading.value = false;
          modal.value.click();
          console.log(error);
        })
    }

    function remove(id) {
      loading.value = true;
      axios.post('/dashboard/remove-service', {
        id: id
      })
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success(" حذف سرویس با موفقیت انجام شد");
          modal.value.click();
        })
        .catch(function (error) {
          toast.error("حذف سرویس با خطا مواجه شد");
          loading.value = false;
          modal.value.click();
          console.log(error);
        })
    }

    function closeModal(storage) {
      modal.value.click();
    }


    function getData() {
      axios.post('/dashboard/get-services', { type: "jupyter" })
        .then(function (response) {
          services.value = response.data
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
      axios.post('/dashboard/get-plans', {type: "jupyter"})
        .then(function (response) {
          plans.value = response.data;
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })

      axios.post('/dashboard/get-storages', {})
        .then(function (response) {
          user_storage.value = response.data;
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }

    function getServiceStorage(storage) {
      service_storage.value = storage;

    }
    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, modal, plans, send, services, price, change_price, data, user_storage, service_storage, restart, remove, closeModal }
  }

}
</script>

<style scoped>
.pointer {
  cursor: pointer;
  height: 30px;
  margin-right: 2px;
}
</style>