<template>
  <div>
    <router-view name="main" />
  </div>
</template>
<script>
import { inject } from "vue";
import { io } from "socket.io-client";
import UserSocket from "@/socket/users.js"
import { provide } from '@vue/runtime-core';

export default {
  setup() {
    let socket = inject('socket')
    let host = inject('host')
    socket = io(`http://${host}/users`);
    provide('socket', socket)
    UserSocket(socket)
  }
}
</script>

<style>
@font-face {
  font-family: 'IRSans';
  src: url('./assets/font/IRANSansWeb.ttf');
}

#app {
  font-family: IRSans, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  direction: rtl;
}


.Vue-Toastification__toast-body.custom-class-1 {
  font-family: IRSans;
}
</style>
