<template>
  <div>

    <div class="mt-3 col-md-12">
      <div class="shadow p-3 mb-2 bg-body rounded d-flex align-items-center">
        <input type="search" class="form-control" id="exampleInputEmail1" placeholder="جست و جوی سرویس ..."
          v-model="search">
      </div>
    </div>

    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div class="row" v-else>
      <div class="mt-3 col-md-12" v-for="service in services" :key="service">
        <div class="shadow p-3 mb-2 bg-body rounded d-flex align-items-center">
          <div>
            {{ service.plan.name }}
            <span class="badge rounded-pill bg-warning" v-if="service.status == 'pending'">انتظار</span>
            <span class="badge rounded-pill bg-warning" v-if="service.status == 'queue'">صف</span>
            <span class="badge rounded-pill bg-success" v-if="service.status == 'active'">فعال</span>
            <span class="badge rounded-pill bg-danger" v-if="service.status == 'error'">خطا</span>
            <span class="badge rounded-pill bg-primary" v-if="service.status == 'finished'">پایان</span>
            <span class="badge rounded-pill bg-secondary me-1">{{ service.user.name }}</span>
            <br>
            <small>G:{{ service.plan.gpu.name }}-C:{{ service.plan.cpu_value }}-R:{{ service.plan.ram_value }}-D:{{
              service.plan.disk_value }}</small>
          </div>
          <div class="me-auto">
            <router-link class="btn btn-primary" :to="'/admin/services/' + service._id">مدیریت</router-link>
          </div>
        </div>
      </div>
    </div>

    <nav aria-label="Page navigation" dir="ltr">
      <ul class="pagination justify-content-center">
        <li v-for="p in page" :key="p" class="page-item">
          <a v-if="p == active_page" class="page-link active" href="#" @click="getData(p)">{{ p }}</a>
          <a v-else class="page-link" href="#" @click="getData(p)">{{ p }}</a>
        </li>
      </ul>
    </nav>

  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted, watch } from '@vue/runtime-core';
export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const modal = ref();
    const plans = ref([]);
    const services = ref([]);
    const price = ref(0);
    const data = ref({});
    const page = ref(1);
    const active_page = ref(0);
    const search = ref('')

    function send() {
      loading.value = true;
      axios.post('/admin/new-service', data.value)
        .then(function (response) {
          getData(1)
          loading.value = false;
          toast.success("افزودن سرور با موفقیت انجام شد");
          modal.value.click();
        })
        .catch(function (error) {
          toast.error("افزودن سرور با خطا مواجه شد");
          loading.value = false;
          modal.value.click();
          console.log(error);
        })
    }
    function getData(page_id, search = '') {
      active_page.value = page_id;
      axios.post('/admin/get-services', { page: page_id, search })
        .then(function (response) {
          services.value = response.data.services
          if (response.data.page != null) {
            page.value = parseInt(response.data.page / 20) + 1;
          }
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }
    onMounted(() => {
      getData(1)
    })

    watch(search, (sr) => {
      getData(1, sr)
    })

    return { get_data_loading, loading, modal, plans, send, services, price, data, page, active_page, getData, search }
  }

}
</script>

<style scoped>
.pointer {
  cursor: pointer;
  width: 30px;
}
</style>