<template>
  <div>

    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
      جدید +
    </button>


    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div class="row" v-else>
      <div class="mt-3 col-md-4" v-for="hardware in hardwares" :key="hardware">
        <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">{{ hardware.name }}
          <button class="btn btn-primary me-auto" data-bs-toggle="modal" data-bs-target="#edit"
            @click="getHardware(hardware._id)">ویرایش</button>
        </div>
      </div>
    </div>


    <div class="modal fade" id="exampleModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">سخت افزار جدید</h5>
          </div>
          <div class="modal-body">

            <div dir="ltr">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="cpu"
                  v-model="data.type" checked>
                <label class="form-check-label" for="inlineRadio1">CPU</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="gpu"
                  v-model="data.type">
                <label class="form-check-label" for="inlineRadio2">GPU</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="ram"
                  v-model="data.type">
                <label class="form-check-label" for="inlineRadio3">Ram</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="disk"
                  v-model="data.type">
                <label class="form-check-label" for="inlineRadio3">Disk</label>
              </div>

              <div class="col-md-12 mt-2">
                <input type="text" class="form-control" placeholder="نام" v-model="data.name">
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="send()" :disabled="loading">
              افزودن
              <span class="spinner-border spinner-border-sm" v-show="loading"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Edit Modal-->
    <div class="modal fade" id="edit" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">ویرایش</h5>
          </div>
          <div class="modal-body">
            <div v-if="edit_data_loading">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
            <div dir="ltr" v-else>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="cpu"
                  v-model="edit_data.type" checked>
                <label class="form-check-label" for="inlineRadio1">CPU</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="gpu"
                  v-model="edit_data.type">
                <label class="form-check-label" for="inlineRadio2">GPU</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="ram"
                  v-model="edit_data.type">
                <label class="form-check-label" for="inlineRadio3">Ram</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="disk"
                  v-model="edit_data.type">
                <label class="form-check-label" for="inlineRadio3">Disk</label>
              </div>

              <div class="col-md-12 mt-2">
                <input type="text" class="form-control" placeholder="نام" v-model="edit_data.name">
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="edit()" :disabled="loading">
              ویرایش
              <span class="spinner-border spinner-border-sm" v-show="loading"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const edit_data_loading = ref(false);
    const toast = inject("toast");
    const modal = ref();
    const hardwares = ref([]);
    const edit_data = ref({})
    const data = ref({
      name: '',
      type: 'cpu'
    });

    function send() {
      loading.value = true;
      axios.post('/admin/new-hardware', data.value)
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("افزودن سخت افزار با موفقیت انجام شد");
          modal.value.click();
        })
        .catch(function (error) {
          toast.error("افزودن سخت افزار با خطا مواجه شد");
          loading.value = false;
          modal.value.click();
          console.log(error);
        })
    }

    function edit() {
      loading.value = true;
      axios.post('/admin/edit-hardware', edit_data.value)
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("ویرایش سخت افزار با موفقیت انجام شد");
          modal.value.click();
        })
        .catch(function (error) {
          toast.error("ویرایش سخت افزار با خطا مواجه شد");
          loading.value = false;
          modal.value.click();
          console.log(error);
        })
    }

    function getData() {
      axios.post('/admin/get-hardwares', {})
        .then(function (response) {
          hardwares.value = response.data
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }

    function getHardware(id) {
      edit_data_loading.value = true;
      axios.post('/admin/get-hardware', { id: id })
        .then(function (response) {
          edit_data.value = response.data
          edit_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          edit_data_loading.value = false;
        })
    }

    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, modal, hardwares, data, send, edit, getHardware, edit_data_loading, edit_data }
  }

}
</script>

<style></style>