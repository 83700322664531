<template>
    <div>
        <div class="col-md-12 pe-3 d-flex align-items-center">
            <a href="https://jupyto.com">
                <img src="@/assets/images/logo.png" class="logo">
            </a>
            <a href="#" class="me-auto ms-2" @click="$router.go(-1)">بازگشت</a>
        </div>
        <div class="txt">
            <h1 dir="ltr">404 Page Not Found</h1>
            <p>صفحه مورد نظر یافت نشد</p>
        </div>

    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.logo {
    display: block;
    margin-left: auto;
    height: 50px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.txt {
    text-align: center;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    top: 50%;
    right: 0;
}
</style>