<template>
  <div>
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
      دیسک جدید +
    </button>

    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div class="row" v-else>
      <div class="mt-3 col-md-12" v-for="storage in storages" :key="storage">
        <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center">
          <div>
            {{ storage.size }} گیگابایت
            <span class="badge rounded-pill bg-warning" v-if="storage.status == 'pending'">انتظار</span>
            <span class="badge rounded-pill bg-success" v-if="storage.status == 'active'">فعال</span>
            <span class="badge rounded-pill bg-warning" v-if="storage.status == 'hover'">معلق</span>
            <span class="badge rounded-pill bg-danger" v-if="storage.status == 'error'">خطا</span>
            <span class="badge rounded-pill bg-primary" v-if="storage.status == 'finished'">پایان</span>
          </div>
          <div class="me-auto">
            <box-icon class="pointer" name='cog' animation='tada-hover' size="cssSize" title="تنظیمات اتصال"
              color='#455A64' data-bs-toggle="modal" data-bs-target="#storage" @click="getStorage(storage._id)"></box-icon>
            <box-icon class="pointer" name='dots-vertical-rounded' size="cssSize" title="گزینه ها" color='#455A64'
              data-bs-toggle="dropdown"></box-icon>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item"  @click="remove(storage._id)">حذف سرویس</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>



    <div class="modal fade" id="exampleModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">ذخیره ساز جدید</h5>
          </div>
          <div class="modal-body">

            <select class="form-select" v-model="data.plan" @change="change_price()">
              <option v-for="plan in plans" :key="[plan]" :value="plan._id">{{ plan.size }} گیگابایت</option>
            </select>

          </div>
          <div class="modal-footer">
            <p class="ms-auto">ساعتی {{ price.toLocaleString() }} تومان</p>
            <button type="button" class="btn btn-primary" @click="send()" :disabled="loading">
              سفارش
              <span class="spinner-border spinner-border-sm" v-show="loading"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Storage Modal-->
    <div class="modal fade" id="storage" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="setting_modal"></button>
            <h5 class="modal-title" id="exampleModalLabel">مشخصات استوریج</h5>
          </div>
          <div class="modal-body">
            {{ storage }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const modal = ref();
    const setting_modal = ref();
    const plans = ref([]);
    const storages = ref([]);
    const storage = ref({});
    const price = ref(0);
    const data = ref({});


    function change_price() {
      plans.value.forEach(pl => {
        if (pl._id == data.value.plan) {
          return price.value = pl.price;
        }
      });

    }

    function send() {
      loading.value = true;
      axios.post('/dashboard/new-storage', data.value)
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("افزودن استوریج با موفقیت انجام شد");
          modal.value.click();
        })
        .catch(function (error) {
          toast.error("افزودن استوریج با خطا مواجه شد");
          loading.value = false;
          modal.value.click();
          console.log(error);
        })
    }

    function getStorage(id) {
      axios.post('/dashboard/get-storage', {id: id})
        .then(function (response) {
          storage.value = response.data
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }

    function remove(id) {
      axios.post('/dashboard/remove-storage', {id: id})
        .then(function (response) {
          toast.success("حذف استوریج با موفقیت انجام شد");
          get_data_loading.value = false;
          getData()
        })
        .catch(function (error) {
          console.log(error);
          toast.error("حذف استوریج با خطا مواجه شد");
          get_data_loading.value = false;
        })
    }


    function getData() {
      axios.post('/dashboard/get-storages', {})
        .then(function (response) {
          storages.value = response.data
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
      axios.post('/dashboard/get-storages-plans', {})
        .then(function (response) {
          plans.value = response.data;
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }
    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, modal, plans, send, storages, price, change_price, data, storage, getStorage, remove, setting_modal }
  }

}
</script>

<style scoped>
.pointer {
  cursor: pointer;
  height: 30px;
  margin-right: 2px;
}
</style>