<template>
  <div>
    <div class="shadow p-3 mb-3 bg-body rounded">
      <button v-if="data.length == 0" class="btn btn-primary" @click="configSetting()">کانفیگ تنظیمات</button>
      <div class="row">
        <div class="col-md-4" v-for="setting in data" :key="setting">
          <label class="form-label">{{ setting.name }}</label>
          <input type="text" class="form-control" v-model="setting.value">
          <button class="btn btn-primary" @click="send(setting._id)">ویرایش</button>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
export default {
  setup() {
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const data = ref({});

    function send(id) {
      loading.value = true;
      let value;
      data.value.forEach((el) => {
        if (el._id == id) {
          value = el.value;
        }
      })
      axios.post('/admin/edit-settings', { _id: id, value: value })
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("ویرایش تنظیمات با موفقیت انجام شد");
        })
        .catch(function (error) {
          toast.error("ویرایش تنظیمات با مشکل مواجه شد");
          loading.value = false;
          console.log(error);
        })

    }

    function configSetting() {
      axios.post('/admin/config-settings', {})
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("کانفیگ  با موفقیت انجام شد");
        })
        .catch(function (error) {
          toast.error("کانفیگ با مشکل مواجه شد");
          loading.value = false;
          console.log(error);
        })
    }

    function getData() {
      axios.post('/admin/get-settings', {})
        .then(function (response) {
          data.value = response.data;
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }
    onMounted(() => {
      getData()
    })

    return { get_data_loading, loading, send, data, configSetting }
  }
}
</script>

<style></style>