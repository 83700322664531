<template>
    <div>

        <div class="mt-3 col-md-12">
            <div class="shadow p-3 mb-2 bg-body rounded d-flex align-items-center">
                <input type="search" class="form-control" id="exampleInputEmail1" placeholder="جست و جوی گزارش ..."
                    v-model="search">
            </div>
        </div>

        <div class="text-center mt-5" v-if="get_data_loading">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <div class="row" v-else>
            <div class="mt-3" v-for="log in logs" :key="log">
                <div class="shadow p-3 mb-3 bg-body rounded">
                    <div class="d-flex ">
                        کاربر: {{ log.user }}
                        سرویس: {{ log.service }}
                        وقوع: {{ log.state }}
                        <box-icon type='solid' name='chevron-down' data-bs-toggle="collapse" class="me-auto"
                            :data-bs-target="'#collapse' + log._id" aria-expanded="false"></box-icon>
                    </div>

                    <div class="collapse mt-3" :id="'collapse' + log._id" dir="ltr">{{ log.message }}</div>
                </div>
            </div>
        </div>

        <nav aria-label="Page navigation" dir="ltr">
            <ul class="pagination justify-content-center">
                <li v-for="p in page" :key="p" class="page-item">
                    <a v-if="p == active_page" class="page-link active" href="#" @click="getData(p)">{{ p }}</a>
                    <a v-else class="page-link" href="#" @click="getData(p)">{{ p }}</a>
                </li>
            </ul>
        </nav>

    </div>
</template>
  
<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted, watch } from '@vue/runtime-core';
export default {
    setup() {
        const loading = ref(false);
        const get_data_loading = ref(true);
        const toast = inject("toast");
        const modal = ref();
        const logs = ref([]);
        const edit_data = ref({
            value: 0
        })
        const edit_data_loading = ref(false);
        const page = ref(1);
        const active_page = ref(0);
        const search = ref('')

        function getData(page_id, search = '') {
            active_page.value = page_id;
            axios.post('/admin/get-logs', { page: page_id, search })
                .then(function (response) {
                    logs.value = response.data.logs
                    if (response.data.page != null) {
                        page.value = parseInt(response.data.page / 20) + 1;
                    }
                    get_data_loading.value = false;
                })
                .catch(function (error) {
                    console.log(error);
                    get_data_loading.value = false;
                })
        }

        function getTransaction(id) {
            edit_data_loading.value = true;
            axios.post('/admin/get-transaction', { id: id })
                .then(function (response) {
                    edit_data.value = response.data
                    edit_data_loading.value = false;
                })
                .catch(function (error) {
                    console.log(error);
                    edit_data_loading.value = false;
                })
        }
        onMounted(() => {
            getData(1)
        })

        watch(search, (sr) => {
            getData(1, sr)
        })

        return { get_data_loading, loading, modal, logs, edit_data, edit_data_loading, getTransaction, page, active_page, getData, search }
    }

}
</script>
  
<style></style>