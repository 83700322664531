import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import 'boxicons'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import Toast from "vue-toastification";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

let host = 'localhost:3000'
const app = createApp(App);
axios.defaults.baseURL = `http://${host}/api`;
axios.defaults.headers.common['Authorization'] = JSON.stringify({ admin: localStorage.getItem("tokenAdmin"), user: localStorage.getItem("token") });

const options = {
    bodyClassName: ["custom-class-1"],
    rtl: true,
    position: "top-left"
};
app.use(Toast, options);

//toast message
const toast = useToast();
app.provide("toast", toast)
app.provide('host', host)
app.provide('socket')

app.use(router);
app.mount('#app');




setInterval(() => {
    
    if (localStorage.getItem("token") != null) {
        let expire = new Date(localStorage.getItem("tokenExpire"))
        let now = new Date().getTime();
        if (now >= expire) {
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpire");
            router.push('/dashboard/login');
            toast.error("توکن شما منقضی شده است");
        }
    }
    if (localStorage.getItem("tokenAdmin") != null) {
        let expire = new Date(localStorage.getItem("tokenAdminExpire"))
        let now = new Date().getTime();
        if (now >= expire) {
            localStorage.removeItem("tokenAdmin");
            localStorage.removeItem("tokenAdminExpire");
            router.push('/admin/login');
            toast.error("توکن شما منقضی شده است");
        }
    }
}, 1000);
