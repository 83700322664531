<template>
  <div>
    <div ref="sidebar" class="sidebar" style="width: 0px">
      <div style="width: 250px;">
        <div class="d-flex align-items-center">
          <div class="col-md-12 pe-3">
            <img src="@/assets/images/logo2.png" class="logo">
          </div>
          <a href="javascript:void(0)" class="closebtn me-auto p-0 m-0 ps-3" @click="Nav()" v-show="block">&times;</a>
        </div>
        <router-link to="/admin" active-class="" @click="close()">
          <div>داشبورد</div>
        </router-link>
        <router-link to="/admin/services" @click="close()">
          <div>سرویس ها</div>
        </router-link>
        <router-link to="/admin/servers" @click="close()">
          <div>سرور ها</div>
        </router-link>
        <router-link to="/admin/plans" @click="close()">
          <div>پلن ها</div>
        </router-link>
        <router-link to="/admin/hardwares" @click="close()">
          <div>سخت افزارها</div>
        </router-link>
        <router-link to="/admin/images" @click="close()">
          <div>ایمیج ها</div>
        </router-link>
        <router-link to="/admin/storages" @click="close()">
          <div>دیسک های ابری</div>
        </router-link>
        <router-link to="/admin/storage-plan" @click="close()">
          <div>پلن دیسک ابری</div>
        </router-link>
        <router-link to="/admin/users" @click="close()">
          <div>کاربران</div>
        </router-link>
        <router-link to="/admin/payments" @click="close()">
          <div>پرداخت ها</div>
        </router-link>
        <router-link to="/admin/supports" @click="close()">
          <div>پشتیبانی</div>
        </router-link>
        <router-link to="/admin/logs" @click="close()">
          <div>گزارشات</div>
        </router-link>
        <router-link to="/admin/settings" @click="close()">
          <div>تنظیمات</div>
        </router-link>
      </div>
    </div>
    <div class="top-nav d-flex shadow " ref="navbar">
      <box-icon name='menu' size='md' color='#ECF0F1' class="mt-2 me-2" @click="Nav()"></box-icon>
      <div class="me-auto ps-3 pt-1 mt-1">
        <div class="dropdown">
          <box-icon name='user-circle' size='md' color='#ECF0F1' class="userIcon" data-bs-toggle="dropdown"></box-icon>
          <ul class="dropdown-menu">
            <li><button class="dropdown-item" type="button">پروفایل</button></li>
            <li><button class="dropdown-item" type="button">پشتیبانی</button></li>
            <li><button class="dropdown-item" type="button">خروج</button></li>
          </ul>
        </div>

      </div>

    </div>
    <div class="side-main" ref="main">
      <router-view name="admin"></router-view>
    </div>
    <div class="sidebar-block" v-show="block" @click="Nav()"></div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
export default {
  setup() {
    const sidebar = ref()
    const main = ref()
    const navbar = ref()
    const block = ref(false)

    function Nav() {
      if (window.innerWidth >= 450) {
        if (sidebar.value.style.width == "0px") {
          sidebar.value.style.width = "250px";
          main.value.style.marginRight = "250px";
          navbar.value.style.marginRight = "260px";
        } else {
          sidebar.value.style.width = "0";
          main.value.style.marginRight = "0";
          navbar.value.style.marginRight = "5px";
        }
      } else {
        if (sidebar.value.style.width == "0px") {
          sidebar.value.style.width = "250px";
          main.value.style.marginRight = "0px";
          navbar.value.style.marginRight = "5px";
          block.value = true;
        } else {
          sidebar.value.style.width = "0";
          main.value.style.marginRight = "0";
          navbar.value.style.marginRight = "5px";
          block.value = false;
        }
      }
    }

    function close() {
      if (window.innerWidth < 450) {
        if (sidebar.value.style.width != "0px") {
          Nav()
        }
      }
    }

    onMounted(() => {
      if (window.innerWidth >= 450) {
        Nav()
      }
    })


    return { Nav, sidebar, main, navbar, block, close }
  }
}
</script>

<style scoped>
.sidebar {
  height: calc(100% - 10px);
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  background-color: #34495E;
  overflow-x: hidden;
  padding-top: 15px;
  transition: 0.3s;

  border-radius: 10px;
  margin: 5px 5px 0px 5px;
}

.sidebar a {
  margin: 15px 5px 15px 5px;
  padding-right: 10px;
  padding-left: 10px;
  text-decoration: none;
  font-size: 25px;
  color: #ECF0F1;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #BDC3C7;
}

.sidebar .closebtn {

  font-size: 36px;

}

.openbtn {
  font-size: 25px;
  cursor: pointer;
  color: #ECF0F1;
  border: none;
  background-color: unset;
}

.openbtn:hover {
  background-color: #f1f1f1;
  background-color: unset;
}


.side-main {
  transition: margin-right .3s;
  padding: 20px;
}

.top-nav {
  border-radius: 10px;
  margin: 5px 5px 0px 5px;
  height: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #34495E;
  transition: margin-right .3s;
}

.sidebar-block {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #818181;
  opacity: 0.5;
  top: 0;
  right: 0;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  margin-bottom: 15px;
}

.router-link-active div {
  border-radius: 10px;
  width: 100%;
  margin-right: 0px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #526f8d;
  transition: padding .2s;
}

.router-link-exact-active div {
  border-radius: 10px;
  width: 100%;
  margin-right: 0px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #526f8d;
  transition: padding .2s;
}

.userIcon {
  cursor: pointer;
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;

  }

  .sidebar a {
    font-size: 18px;
  }
}
</style>