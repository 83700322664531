<template>
  <div>

    <p>سرویس: {{ route.params.id }}
      <span class="badge rounded-pill bg-secondary" v-if="data.service_type == 'jupyter'">Jupyter</span>
      <span class="badge rounded-pill bg-secondary" v-if="data.service_type == 'server'">Server</span>
    </p>

    <div class="text-center mt-5" v-if="get_data_loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div v-else>
      <div class="shadow p-3 mb-3 bg-body rounded">
        <div calss="">
          وضعیت:
          <span class="badge rounded-pill bg-warning" v-if="data.status == 'pending'">انتظار</span>
          <span class="badge rounded-pill bg-warning" v-if="data.status == 'queue'">صف</span>
          <span class="badge rounded-pill bg-success" v-if="data.status == 'active'">فعال</span>
          <span class="badge rounded-pill bg-danger" v-if="data.status == 'error'">خطا</span>
          <span class="badge rounded-pill bg-primary" v-if="data.status == 'finished'">پایان</span>
        </div>
        <div class="d-flex justify-content-between flex-wrap mt-2 mb-2">
          <p calss="col-md-2">کاربر: <router-link :to="'/admin/users/' + data.user._id">{{ data.user.name }}</router-link>
          </p>
          <p calss="col-md-2">سرور: <router-link :to="'/admin/servers/' + data.server._id">{{
            data.server.ip }}</router-link></p>
          <p calss="col-md-2">پلن: {{ data.plan.name }}</p>
        </div>
        مشخصات:
        <div v-if="data.service_type == 'server'" class="pt-2 pe-3">
          ایمیج: {{ data.image }}
          <br>
          <div class="row mt-2">
            <div class="col-md-3">
              <label class="form-label">نام کاربری</label>
              <input type="text" class="form-control" :value="data.username" readonly>
            </div>
            <div class="col-md-3">
              <label class="form-label">رمز عبور</label>
              <input type="text" class="form-control" :value="data.password" readonly>
            </div>
          </div>
        </div>
        <div v-else-if="data.service_type == 'jupyter'" class="pt-2 pe-3">
          آدرس اتصال: <a target="_blank" :href="'https://' + data._id + '.co.jupyto.com?token=' + data.token" dir="ltr">
            https://{{ data._id }}.co.jupyto.com?token={{ data.token }}</a>
        </div>
      </div>

      <p>عملیات</p>
      <div class="shadow p-3 mb-3 bg-body rounded">
        <div class="text-center mt-5" v-if="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
        <div v-else>
          <div class="row m-0" v-if="data.service_type == 'jupyter'">
            <button type="button" class="btn btn-primary col-md-2 mx-auto m-1" @click="restart">ری استارت</button>
            <button type="button" class="btn btn-primary col-md-2 mx-auto m-1" @click="getStorage">استوریج</button>
            <button type="button" class="btn btn-primary col-md-2 mx-auto m-1" @click="getStatus">وضعیت</button>
            <button type="button" class="btn btn-danger col-md-2 mx-auto m-1" @click="remove">حذف سرویس</button>
          </div>
          <div class="row m-0" v-else-if="data.service_type == 'server'">
            <button type="button" class="btn btn-primary col-md-2 mx-auto m-1" @click="start">روشن</button>
            <button type="button" class="btn btn-primary col-md-2 mx-auto m-1" @click="stop">خاموش</button>
            <button type="button" class="btn btn-primary col-md-2 mx-auto m-1" @click="restart">ری استارت</button>
            <button type="button" class="btn btn-primary col-md-2 mx-auto m-1" @click="getFirewall" data-bs-toggle="modal"
              data-bs-target="#network">فایروال</button>
            <button type="button" class="btn btn-primary col-md-2 mx-auto m-1" @click="getStatus">وضعیت</button>
            <button type="button" class="btn btn-danger col-md-1 mx-auto m-1" @click="remove">حذف سرویس</button>
          </div>
        </div>
      </div>

      <p>گزارشات</p>
      <div class="shadow p-3 mb-3 bg-body rounded">
        <div class="">

        </div>
      </div>

      <!--status-->

      <!--firewall-->
      <div class="modal fade" id="network" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" ref="modal"></button>
              <h5 class="modal-title" id="exampleModalLabel">فایروال</h5>
            </div>
            <div class="modal-body">

              <div class="row">
                <div class="col-md-4 mb-3">
                  <label class="form-label">پورت</label>
                  <input type="number" class="form-control" v-model="nat_data.port">
                </div>
                <div class="col-md-4 mb-3">
                  <label class="form-label">پرتکل</label>
                  <select class="form-select" v-model="nat_data.protocol">
                    <option value="tcp" selected>TCP</option>
                    <option value="udp">UDP</option>
                  </select>
                </div>
                <div class="col-md-4 mb-3">
                  <button type="button" class="btn btn-primary add-roll" @click="newNat()" :disabled="loading">
                    افزودن
                    <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                  </button>
                </div>
              </div>
              <div class="mt-2">
                <div class="shadow p-3 mb-3 bg-body rounded d-flex align-items-center" v-for="nat in nats" :key="nat">
                  {{ nat.protocol }}
                  <a target="_blank" :href="'http://' + nat.public + ':' + nat.public_port" class="me-2 ms-2">{{
                    nat.public }}:{{ nat.public_port }}</a> -> {{ nat.local_port }}
                  <box-icon name='trash' class="me-auto trash" @click="removeNat(nat._id)" :disabled="loading"></box-icon>
                  <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!--storage-->

    </div>

  </div>
</template>

<script>
import { ref } from 'vue';
import { inject } from '@vue/runtime-core';
import axios from 'axios';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
export default {
  setup() {
    const route = useRoute()
    const loading = ref(false);
    const get_data_loading = ref(true);
    const toast = inject("toast");
    const data = ref({});
    const nats = ref([]); //firewall Nats
    const nat_data = ref({});

    function send() {

      loading.value = true;
      axios.post('/admin/edit-service', data.value)
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("ثبت مشخصات  با موفقیت انجام شد");
        })
        .catch(function (error) {
          toast.error("ثبت مشخصات با مشکل مواجه شد");
          loading.value = false;
          console.log(error);
        })

    }

    function getData() {
      axios.post('/admin/get-service', { id: route.params.id })
        .then(function (response) {
          data.value = response.data;
          get_data_loading.value = false;
        })
        .catch(function (error) {
          console.log(error);
          get_data_loading.value = false;
        })
    }
    onMounted(() => {
      getData()
    })

    function start() { //just server
      loading.value = true;
      axios.post('/admin/service-start', { id: data.value._id })
        .then(function (response) {
          loading.value = false;
          toast.success("عملیات با موفقیت انجام شد");
        })
        .catch(function (error) {
          console.log(error);
          loading.value = false;
          toast.error("عملیات با خطا مواجه شد");
        })
    }
    function stop() { //just server
      loading.value = true;
      axios.post('/admin/service-stop', { id: data.value._id })
        .then(function (response) {
          loading.value = false;
          toast.success("عملیات با موفقیت انجام شد");
        })
        .catch(function (error) {
          console.log(error);
          loading.value = false;
          toast.error("عملیات با خطا مواجه شد");
        })
    }
    function restart() {
      loading.value = true;
      axios.post('/admin/service-restart', { id: data.value._id })
        .then(function (response) {
          loading.value = false;
          toast.success("عملیات با موفقیت انجام شد");
        })
        .catch(function (error) {
          console.log(error);
          loading.value = false;
          toast.error("عملیات با خطا مواجه شد");
        })
    }

    function getNats() { //just server
      loading.value = true;
      axios.post('/admin/get-service-nats', { id: data.value._id })
        .then(function (response) {
          nats.value = response.data;
          loading.value = false;
          toast.success("عملیات با موفقیت انجام شد");
        })
        .catch(function (error) {
          console.log(error);
          loading.value = false;
          toast.error("عملیات با خطا مواجه شد");
        })
    }

    function newNat() {  //just server
      loading.value = true;
      axios.post('/admin/new-service-nat', {
        id: data.value._id,
        protocol: nat_data.value.protocol,
        port: nat_data.value.port,
      })
        .then(function (response) {
          getNats()
          loading.value = false;
          toast.success("فایروال بروزرسانی شد");
        })
        .catch(function (error) {
          toast.error("خطا در بروزرسانی فایروال");
          loading.value = false;
          console.log(error);
        })
    }
    function removeNat(id) { //just server
      loading.value = true;
      axios.post('/admin/remove-service-nat', { id: id })
        .then(function (response) {
          loading.value = false;
          toast.success("فایروال بروزرسانی شد");
          getNats()
        })
        .catch(function (error) {
          loading.value = false;
          toast.error("خطا در بروزرسانی فایروال");
        })
    }

    function getStatus() {

    }

    function updateStatus() {

    }

    function remove() {
      loading.value = true;
      axios.post('/admin/remove-service', { id: data.value._id })
        .then(function (response) {
          getData()
          loading.value = false;
          toast.success("عملیات با موفقیت انجام شد");
        })
        .catch(function (error) {
          console.log(error);
          loading.value = false;
          toast.error("عملیات با خطا مواجه شد");
        })
    }

    function getStorage() { // just jupyter

    }

    function updateStorage() { // just jupyter

    }


    return {
      get_data_loading, loading, send, data, route, nat_data,
      start, stop, restart, removeNat, newNat, getNats, getStatus, updateStatus, remove, getStorage, updateStorage,
    }
  }
}
</script>

<style>
.add-roll {
  width: 100%;
  margin-top: 32px;
}
</style>